export const AToZ = [
  "A",
  "B",
  "C",
  "D",
  "E",
  "F",
  "G",
  "H",
  "I",
  "J",
  "K",
  "L",
  "M",
  "N",
  "O",
  "P",
  "Q",
  "R",
  "S",
  "T",
  "U",
  "V",
  "W",
  "X",
  "Y",
  "Z",
];

export const USER_VERIFICATION_STATUS = {
  fully_verified: {
    key: "fully_verified", // * fully verified and temp verified have same previllages but temp verified will be blocked after 30 days
  },
  not_verified: {
    key: "not_verified",
  },
  public: {
    key: "public",
  },
};

export const AUTH_ACTIONS = {
  HIDE: "HIDE",
  DEFFER_CLICKS: "DEFFER_CLICKS",
  BECOME_VERIFIED: "BECOME_VERIFIED",
  REPLACE_CHILDREN: "REPLACE_CHILDREN",
  CALLBACK: "CALLBACK",
};

export const BUSINESS_DASHBOARD_EDIT_DETAILS_MENU_LIST = [
  {
    key: "11",
    to: "business-type",
    label: "Business Type",
    show: true,
  },
  {
    key: "12",
    to: "business-info",
    label: "Business Information",
    show: true,
  },
  {
    key: "13",
    to: "additional-info",
    label: "Additional Information",
    show: true,
  },
  {
    key: "14",
    to: "call-to-action",
    label: "Call To Action Button",
    show: true,
  },
  {
    key: "15",
    to: "staff",
    label: "Staff",
    show: true,
  },
  {
    key: "16",
    to: "business-address",
    label: "Address",
    show: true,
  },
  {
    key: "17",
    to: "media",
    label: "Photos & Videos",
    show: true,
  },
  {
    key: "18",
    to: "business-profile",
    label: "Profile and Cover Images",
    show: true,
  },
];

export const ORGANISATION_BRANCH_DASHBOARD_MENU_LIST = [
  {
    key: "0",
    label: "Overview",
    to: "overview",
    show: true,
  },

  {
    key: "3",
    label: "Followers",
    to: "followers",
    show: true,
  },
  {
    key: "4",
    label: "Mentions",
    to: "mentions",
    show: true,
  },
  {
    key: "1",
    label: "Edit Organisation Details",
    to: "edit-details",
    show: true,
  },
];

export const ORGANISATION_HEADQUARTER_DASHBOARD_MENU_LIST = [
  {
    key: "0",
    label: "Overview",
    to: "overview",
    show: true,
  },
  {
    key: "1",
    label: "Branches",
    to: "branches",
    show: true,
  },
  {
    key: "3",
    label: "Followers",
    to: "followers",
    show: true,
  },
  {
    key: "4",
    label: "Mentions",
    to: "mentions",
    show: true,
  },
  {
    key: "1",
    label: "National Message",
    to: "national-message",
    show: true,
  },
  {
    key: "1",
    label: "Recent Posts",
    to: "recent-post",
    show: true,
  },
  {
    key: "1",
    label: "Edit Organisation Details",
    to: "edit-details",
    show: true,
  },
];

export const NEW_ZEALAND_BOUNDS = {
  north: -34.36,
  south: -47.35,
  west: 166.28,
  east: -175.81,
};

export const ALLOWED_DOCUMENT_FORMATS = [
  "pdf",
  "jpg",
  "png",
  "jpeg",
  "pdf",
  "doc",
  "docx",
];

export const UPLOADER_ACCEPT_DOCUMENT_LIST = [
  "image/png",
  "image/jpeg",
  "image/jpg",
  "application/pdf",
  ".doc",
  ".docx",
];
