import React from "react";
import PropTypes from "prop-types";

// components
import MessagePost from "./messagePost/messagePost";

// scss
import "./post.scss";

// constants

function Post({
  type,
  data,
  showMore,
  showSharedCriteria,
  showSave,
  handleEditPost,
  hideImg,
  showMessage,
}) {
  return (
    <div className="nb-post-wrapper">
      <MessagePost
        hideImg={hideImg}
        data={data}
        showMore={showMore}
        showSharedCriteria={showSharedCriteria}
        showSave={showSave}
        handleEditPost={handleEditPost}
      />
    </div>
  );
}

export default Post;

Post.defaultProps = {
  showMore: true,
  showSharedCriteria: true,
  showSave: true,
  type: undefined,
};

Post.propTypes = {
  type: PropTypes.string,
  showMore: PropTypes.bool,
  showSharedCriteria: PropTypes.bool,
  showSave: PropTypes.bool,
  data: PropTypes.objectOf(PropTypes.any).isRequired,
};
