import { useEffect, useMemo, useState } from "react";
import { useRouteMatch, useHistory, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import isEmpty from "lodash/isEmpty";
import { notification } from "antd";

// formatter
import {
  AUTH_ACTIONS,
  PRIVATE_ROUTES,
  USER_VERIFICATION_STATUS,
} from "../constants";

// apis
import mediaApis from "../api/services/media/media";
import tokenActions from "../store/auth/action";
import authApis from "../api/services/auth/auth";

// actions
import globalComponentsActions from "../store/Actions/globalComponentsAction";

export const useNbRoutes = () => {
  const match = useRouteMatch();
  const history = useHistory();
  const me = useSelector((state) => state.me || {});
  const [isAuthenticated, setAuthenticated] = useState(!isEmpty(me));
  const isPrivate = PRIVATE_ROUTES.some(({ path }) => path === match?.path);

  useEffect(() => {
    setAuthenticated(!isEmpty(me));
  }, [me]);

  return {
    private: isPrivate,
    authenticated: isAuthenticated,
    gotoLogin: () => history.push("/login"),
    gotoLanding: () => history.push("/"),
    gotoRoot: () => {
      if (!isAuthenticated) {
        history.push("/");
        return; // * return to landing page, because public user cannot switch profile
      }
      history.push("/home/all");
    },
    getDefaultRedirectionRoute: () => {
      return "/home/all";
    },
  };
};

export const useMediaUploader = () => {
  const upload = async (file) => {
    try {
      return await mediaApis.upload(file);
    } catch (error) {
      throw new Error(error);
    }
  };
  const deleteMedia = async (mId) => {
    try {
      return await mediaApis.deleteMedia(mId);
    } catch (error) {
      throw new Error(error);
    }
  };
  return { upload, deleteMedia };
};

export const useProfileSwitchingGuard = () => {
  const {
    default_display_type: defaultDisplayType,
    default_display: defaultDisplay,
    default_display_id: defaultDisplayId,
    registration_status: registrationStatus, // * means if the user is registed or not, to handle user and organisation/business only profiles
    first_name: firstName,
    last_name: lastName,
  } = useSelector((state) => state.me || {});

  // eslint-disable-next-line consistent-return
  const activate = (item, actionItem) => {
    if (!item && isEmpty(actionItem)) {
      // * return if no action is passed, sort of error handling
      return item;
    }

    let status = "";
    if (defaultDisplay?.type === "business") {
      if (actionItem?.BUSINESS) {
        // * to handle both free and premium business in one shot
        status = "BUSINESS";
      } else if (defaultDisplay?.detail?.business_type === "free") {
        status = "FREE_BUSINESS";
      } else {
        status = "PREMIUM_BUSINESS";
      }
    } else if (defaultDisplay?.type === "publication") {
      status = "PUBLICATION";
    }
    if (actionItem[status]?.action) {
      if (actionItem[status].action === "CALLBACK") {
        return actionItem[status]?.callback();
      }
    } else {
      return item;
    }
  };

  const isAuthor = (authorId) => {
    return Number(authorId) === Number(defaultDisplayId);
  };

  const isOrganisationOwner = (ownerId) => {
    return Number(ownerId) === Number(defaultDisplayId);
  };

  const isUser = () => {
    return defaultDisplayType === "user";
  };
  const isOrganisation = () => {
    return defaultDisplay?.type === "organisation";
  };

  const isPublication = () => {
    return defaultDisplay?.type === "publication";
  };

  const isBusiness = () => {
    return defaultDisplay?.type === "business";
  };

  const isFreeBusiness = () => {
    return defaultDisplay?.detail?.business_type === "free";
  };

  const isPremiumBusiness = () => {
    return defaultDisplay?.detail?.business_type === "premium";
  };

  const isOneOfOrganisationPublicationBusiness = () => {
    return isOrganisation() || isPublication() || isBusiness();
  };
  const isOrganisationOnlyProfile = () => {
    return isOrganisation() && !registrationStatus;
  };

  const isBusinessOnlyProfile = () => {
    return isBusiness() && !registrationStatus;
  };

  const getCurrentProfileInfo = () => {
    let currentProfile = {};

    if (isOrganisation()) {
      currentProfile = { ...defaultDisplay };
    } else if (isBusiness()) {
      currentProfile = { ...defaultDisplay };
    } else {
      currentProfile = {
        name: `${firstName} ${lastName}`,
      };
    }
    return currentProfile;
  };

  return {
    activate,
    isAuthor,
    isOrganisationOwner,
    isOrganisation,
    isPublication,
    isBusiness,
    isFreeBusiness,
    isPremiumBusiness,
    isUser,
    isOneOfOrganisationPublicationBusiness,
    isOrganisationOnlyProfile,
    isBusinessOnlyProfile,
    getCurrentProfileInfo,
  };
};

const useStatus = () => {
  const switchingGuard = useProfileSwitchingGuard();
  let { status = "public" } = useSelector((state) => state.me || {});
  if (status === "temp_verified") status = "fully_verified"; //* temp verified and fully verified are same
  if (switchingGuard.isOrganisationOnlyProfile()) status = "fully_verified"; //* temp verified and fully verified are same
  if (switchingGuard.isBusinessOnlyProfile()) status = "fully_verified"; //* temp verified and fully verified are same
  return status;
};

export const useAuthGuard = () => {
  const status = useStatus();

  // eslint-disable-next-line consistent-return
  function activate(item, authActions) {
    if (!authActions && isEmpty(authActions)) {
      // * return if no action is passed, sort of error handling
      return item;
    }

    if (
      USER_VERIFICATION_STATUS.not_verified.key ||
      USER_VERIFICATION_STATUS.fully_verified.key
    ) {
      if (authActions[status]?.action) {
        if (authActions[status].action === AUTH_ACTIONS.HIDE) {
          return null;
        } else if (authActions[status].action === AUTH_ACTIONS.CALLBACK) {
          return authActions[status]?.callback();
        }
      } else {
        return item;
      }
    }
  }

  function isPublic() {
    return status === "public";
  }
  return { activate, isPublic };
};

export const useProfileSwitcher = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const handleSwitchProfile = async (data) => {
    dispatch(globalComponentsActions.toggleProgressLoading(true));

    try {
      const res = await authApis.switchProfile(data);

      const {
        default_display: defaultDisplay,
        default_display_id: defaultDisplayId,
      } = res.data || {};

      dispatch(tokenActions.saveMe(res.data));
      dispatch(globalComponentsActions.toggleProgressLoading(false));
      switch (defaultDisplay?.type) {
        case "business":
          if (defaultDisplay.is_headquarter) {
            // * goto dashboard for headquarter
            history.push(`/business/${defaultDisplayId}/dashboard`);
          } else {
            history.push(`/business/${defaultDisplayId}/details`);
          }
          break;

        case "organisation":
          if (defaultDisplay.is_headquarter) {
            // * goto dashboard for headquarter
            history.push(
              `/organisation/${defaultDisplayId}/dashboard/overview`
            );
          } else {
            history.push(`/organisation/${defaultDisplayId}`);
          }
          break;

        case "publication":
          history.push(`/publications/${defaultDisplayId}/details`);
          break;

        default:
          history.push("/home/all");
          break;
      }
    } catch (err) {
      dispatch(globalComponentsActions.toggleProgressLoading(false));
      notification.error({
        message: err?.message || "Something went wrong!",
      });
    }
  };

  return { handleSwitchProfile };
};

export const useScript = (
  resourceUrl,
  { load = true, attributes = [], dependency = [] } = {}
) => {
  useEffect(() => {
    if (!load) return;
    const script = document.createElement("script");
    script.setAttribute("src", resourceUrl);
    script.setAttribute("async", true);

    if (attributes.length > 0) {
      attributes.forEach((attribute) => {
        const key = Object.keys(attribute)[0];
        script.setAttribute(key, attribute[key]);
      });
    }
    document.body.appendChild(script);
    // eslint-disable-next-line consistent-return
    return () => {
      document.body.removeChild(script);
    };
  }, [resourceUrl, load, ...dependency]);
};

export const useQuery = () => {
  const { search } = useLocation();

  return useMemo(() => {
    let queryParams = {};
    new URLSearchParams(search).forEach((k, i) => {
      queryParams = {
        ...queryParams,
        [i]: k,
      };
    });

    return queryParams;
  }, [search]);
};
