import { Post, Get, Delete, Put, Patch } from "../../requests";

const getCategories = (id, cancelToken) => {
  return Get(`/posts/categories/${id}`, undefined, cancelToken);
};

const getNeighbours = () => {
  return Get("/user/neighbourhoods/");
};

const getAllPosts = (page) => {
  let url = "/posts";
  if (page) {
    url += `?page=${page}`;
  }
  return Get(url);
};

const getPostById = (id) => {
  return Get(`/posts/${id}`);
};

const getAllPostsByCategoryId = (
  showFilter,
  newsiestFilter,
  categoryId = "",
  type = "",
  pageNo = 1
) => {
  let url;
  if (newsiestFilter === "Newest Activity") {
    // url request for Newest post
    url = showFilter
      ? `/posts?category_id=${categoryId}&neighbourhood=${showFilter}&order_by=is_latest_activity&page=${pageNo}`
      : `/posts?category_id=${categoryId}&order_by=is_latest_activity&page=${pageNo}`;
  } else if (newsiestFilter === "Oldest Posts") {
    // url request for Newest post
    url = showFilter
      ? `/posts?category_id=${categoryId}&neighbourhood=${showFilter}&order_by=is_latest_activity&page=${pageNo}`
      : `/posts?category_id=${categoryId}&order_by=is_oldest_activity&page=${pageNo}`;
  } else {
    // url request for all / neighbourhood /  selected category
    url = showFilter
      ? `/posts?category_id=${categoryId}&neighbourhood=${showFilter}&page=${pageNo}`
      : `/posts?category_id=${categoryId}&page=${pageNo}`;
  }
  if (type) {
    url += `&type=${type}`;
  }
  return Get(url);
};
const getAllPostsByCommunityId = (
  showFilter,
  newsiestFilter,
  categoryId = "",
  type = "",
  pageNo = 1
) => {
  let url;
  if (newsiestFilter === "Newest Activity") {
    // url request for Newest post
    url = showFilter
      ? `/posts?neighbourhood_id=${categoryId}&neighbourhood=${showFilter}&is_latest_activity&page=${pageNo}`
      : `/posts?neighbourhood_id=${categoryId}&is_latest_activity&page=${pageNo}`;
  } else {
    // url request for all / neighbourhood /  selected category
    url = showFilter
      ? // ? `/posts?neighbourhood_id=${categoryId}&neighbourhood=${showFilter}&page=${pageNo}`
        // : `/posts?neighbourhood_id=${categoryId}&page=${pageNo}`;
        `/posts?neighbourhood=${categoryId}&neighbourhood=${showFilter}&page=${pageNo}`
      : `/posts?neighbourhood=${categoryId}&page=${pageNo}`;
  }
  if (type) {
    url += `&type=${type}`;
  }
  return Get(url);
};

const likePost = (id, params) => {
  return Post(`/posts/${id}/like`, params);
};

const unlikePost = (id, params) => {
  return Post(`/posts/${id}/unlike`, params);
};

const commentPost = (id, params) => {
  return Post(`/posts/${id}/comments`, params);
};

const likeComment = (postId, commentId, params) => {
  return Post(`/posts/${postId}/comments/${commentId}/like`, params);
};

const unLikeComment = (postId, commentId, params) => {
  return Post(`/posts/${postId}/comments/${commentId}/unlike`, params);
};

const replyComment = (postId, params) => {
  return Post(`/posts/${postId}/comments`, params);
};

const getPostComments = (postId, page = 1) => {
  return Get(`/posts/${postId}/comments?page=${page}`);
};

const getCommentsReply = (postId, commentId, page = 1) => {
  return Get(`/posts/${postId}/comments?page=${page}&comment_id=${commentId}`);
};

const deleteComment = (postId, commentId) => {
  return Delete(`/posts/${postId}/comments/${commentId}`);
};

const reportComment = (postId, commentId, params) => {
  return Post(`/posts/${postId}/comments/${commentId}/report`, params);
};

const messagePost = (params) => {
  return Post("/posts/", params);
};

const deletePost = (postId) => {
  return Delete(`/posts/${postId}`);
};

const unfollowPost = (postId) => {
  return Post(`/posts/${postId}/unfollow`);
};

const reportPost = (postId, params) => {
  return Post(`/posts/${postId}/report`, params);
};

const onSavePost = (postId) => {
  return Post(`/posts/${postId}/save`);
};

const onUnSavePost = (postId) => {
  return Post(`/posts/${postId}/remove-save`);
};

const onHidePost = (postId) => {
  return Post(`/posts/${postId}/hide`);
};

const onUnHidePost = (postId) => {
  return Post(`/posts/${postId}/unhide`);
};

const muteUser = (userId) => {
  return Post(`/users/${userId}/mute`);
};

const postCategory = () => {
  return Get("/posts/categories/");
};

const getHostList = (url) => {
  return Get(url);
};

const getPostEventHosts = (query) => {
  return Get(`/posts/event-host?q=${query ?? ""}`);
};

const votePoll = (postId, choiceId) => {
  return Post(`posts/${postId}/poll-choice/${choiceId}/vote`);
};

const closePoll = (postId) => {
  return Post(`posts/${postId}/poll`);
};

const disableReplies = (postId) => {
  return Post(`posts/${postId}/comments/disable`);
};

const enableReplies = (postId) => {
  return Post(`posts/${postId}/comments/enable`);
};

const markPurchased = (postId, payload) => {
  return Put(`posts/${postId}/mark-item`, payload);
};

const updatePost = (postId, payload) => {
  return Patch(`/posts/${postId}`, payload);
};

const getPostDetails = (postId) => {
  return Get(`/posts/${postId}`);
};

const getPostsByOrgId = (orgId) => {
  return Get(`/posts?organisation_id=${orgId}`);
};

const postApis = {
  getCategories,
  getNeighbours,
  getAllPosts,
  getPostById,
  likePost,
  unlikePost,
  commentPost,
  likeComment,
  unLikeComment,
  replyComment,
  messagePost,
  getPostComments,
  getAllPostsByCategoryId,
  getCommentsReply,
  postCategory,
  getHostList,
  deletePost,
  unfollowPost,
  reportPost,
  muteUser,
  deleteComment,
  onSavePost,
  onUnSavePost,
  onHidePost,
  onUnHidePost,
  votePoll,
  closePoll,
  disableReplies,
  enableReplies,
  markPurchased,
  reportComment,
  getPostEventHosts,
  updatePost,
  getPostDetails,
  getPostsByOrgId,
  getAllPostsByCommunityId,
};

export default postApis;
