import React from "react";
import PropTypes from "prop-types";

// icons
import { More } from "../../../icons";

// scss
import "./nbMoreButton.scss";

function NbMoreButton({ className, style, onClick }) {
  return (
    <button
      type="button"
      className={`nb-more-button ${className}`}
      style={style}
      onClick={onClick}
    >
      <More height="14px" />
    </button>
  );
}

export default NbMoreButton;

NbMoreButton.defaultProps = {
  className: "",
  style: {},
  onClick: () => null,
};

NbMoreButton.propTypes = {
  style: PropTypes.any,
  className: PropTypes.string,
  onClick: PropTypes.func,
};
