const pusherOptions = (token) => {
  return {
    broadcaster: "pusher",
    key: process.env.REACT_APP_PUSHER_KEY,
    cluster: process.env.REACT_APP_PUSHER_CLUSTER,
    wsHost: process.env.REACT_APP_PUSHER_WSHOST,
    wsPort: process.env.REACT_APP_PUSHER_WSPORT,
    wssPort: process.env.REACT_APP_PUSHER_WSPORT,
    enabledTransports: ["ws", "wss"],
    auth: {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
      },
    },
    authEndpoint: process.env.REACT_APP_PUSHER_AUTHENDPOINT,
    forceTLS: process.env.REACT_APP_PUSHER_FORCETLS,
    disableStats: true,
  };
};

const config = {
  title: process.env.REACT_APP_TITLE,
  baseURL: process.env.REACT_APP_BASE_URL,
  env: process.env.REACT_APP_ENV,
  version: process.env.REACT_APP_VERSION,
  accessKeyId: process.env.REACT_APP_VERSION_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_VERSION_SECRET_ACCESS_KEY,
  uploadSize: process.env.REACT_APP_UPLOAD_LIMIT,
  googleCaptchaKey: process.env.REACT_APP_RECAPTCHA,
  pusherOptions,
};
export default config;
