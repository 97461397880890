export const POST_TYPES = {
  message: "message",
  classified: "classified",
  recommendation: "recommendation",
  poll: "poll",
  event: "event",
  urgent_alerts: "urgent-alert",
  general_post: "generalPost",
  general: "general",
  post_sticky: "post_sticky",
  close_tab: "close_tab",
};

export const PROMOTION_TYPES = {
  sponsored: {
    key: "sponsored",
    label: "Sponsored Post",
  },
  featured: {
    key: "featured",
    label: "Featured Post",
  },
  local_highlight: {
    key: "local_highlight",
    label: "Local Highlight",
  },
  advertisement: {
    key: "advertisement",
    label: "Advertisement",
  },
  neighbourly_partner: {
    key: "neighbourly_partner",
    label: "Book Partner",
  },
};
