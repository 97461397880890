export const ALL_POSTS = "ALL_POSTS";
export const ALL_POSTS_SUCCESS = "ALL_POSTS_SUCCESS";
export const ALL_POSTS_FAILURE = "ALL_POSTS_FAILURE";
export const ALL_POSTS_REMOVE = "ALL_POSTS_REMOVE";

export const GET_NEIGHBOURS = "GET_NEIGHBOURS";
export const GET_NEIGHBOURS_SUCCESS = "GET_NEIGHBOURS_SUCCESS";
export const GET_NEIGHBOURS_FAILURE = "GET_NEIGHBOURS_FAILURE";

export const GET_CATEGORIES = "GET_CATEGORIES";
export const GET_CATEGORIES_SUCCESS = "GET_CATEGORIES_SUCCESS";
export const GET_CATEGORIES_FAILURE = "GET_CATEGORIES_FAILURE";

export const GET_CHILD_CATEGORIES = "GET_CHILD_CATEGORIES";
export const GET_CHILD_CATEGORIES_SUCCESS = "GET_CHILD_CATEGORIES_SUCCESS";
export const GET_CHILD_CATEGORIES_FAILURE = "GET_CHILD_CATEGORIES_FAILURE";

// neighbourly widgets

export const GET_ALL_WIDGETS_DATA = "GET_ALL_WIDGETS_DATA";
export const SET_WIDGETS_DATA = "SET_WIDGETS_DATA";
export const SET_WIDGETS_DATA_LOADING = "GET_WIDGETS_DATA_LOADING";

// for profile switching
export const TOGGLE_PROGRESS_LOADING = "TOGGLE_PROGRESS_LOADING";

// LoginModal
export const SHOW_HIDE_LOGIN_MODAL = "SHOW_HIDE_LOGIN_MODAL";
