import { TOGGLE_PROGRESS_LOADING, SHOW_HIDE_LOGIN_MODAL } from "../constants";

const toggleProgressLoading = (payload) => {
  return {
    type: TOGGLE_PROGRESS_LOADING,
    payload,
  };
};

const showHideLoginModal = (visible) => {
  return {
    type: SHOW_HIDE_LOGIN_MODAL,
    payload: visible,
  };
};

const globalComponentsActions = {
  toggleProgressLoading,
  showHideLoginModal,
};

export default globalComponentsActions;
