import React from "react";
// eslint-disable-next-line import/no-cycle
// eslint-disable-next-line import/no-cycle
import GlobalSearch from "../pages/globalSearch/globalSearch";
import PostView from "../pages/postView/postView";

const Landing = React.lazy(() => import("../pages/landing/landing"));
const Login = React.lazy(() => import("../pages/login/login"));
const Home = React.lazy(
  /* istanbul ignore next */
  () => import("../pages/home/home")
);
const Message = React.lazy(
  /* istanbul ignore next */
  () => import("../pages/message/message")
);
const Neighbours = React.lazy(
  /* istanbul ignore next */
  () => import("../pages/neighbours/neighbours")
);

const AccounttSetting = React.lazy(
  /* istanbul ignore next */
  () => import("../pages/accountSetting/accountSetting")
);
const Profile = React.lazy(
  /* istanbul ignore next */
  () => import("../pages/profile/profile")
);

// about
const AboutUs = React.lazy(() => import("../pages/aboutUs/aboutUs"));

// Terms and conditions
const TermsAndConditions = React.lazy(() =>
  import("../pages/termsAndConditions/termsAndConditions")
);

// Privacy Policy
const PrivacyPolicy = React.lazy(() =>
  import("../pages/privacyPolicy/privacyPolicy")
);

export const PUBLIC_PRIVATE_ROUTES = [
  {
    path: "/about",
    component: AboutUs,
    id: 25,
  },
];

export const PRIVATE_ROUTES = [
  {
    path: "/home",
    component: Home,
    id: "/home",
  },
  {
    path: "/",
    component: Home,
    id: "/",
    exact: true,
  },
  {
    path: "/message",
    component: Message,
    id: "/message",
    exact: true,
  },
  {
    path: "/message/:id",
    component: Message,
    id: "/message/:id",
    exact: true,
  },
  {
    path: "/community-members",
    component: Neighbours,
    id: "/community-members",
    exact: true,
  },
  {
    path: "/profile",
    component: Profile,
    id: "/profile",
    exact: true,
  },
  {
    path: "/profile/:id",
    component: Profile,
    id: "/profile/:id",
    exact: true,
  },
  ...PUBLIC_PRIVATE_ROUTES,
  {
    path: "/account/settings",
    component: AccounttSetting,
    id: "/account/settings",
  },
  {
    path: "/global-search",
    component: GlobalSearch,
    id: "/global-search",
    exact: true,
  },
  {
    path: "/post/view/:id",
    component: PostView,
    id: 25,
  },
];

export const PUBLIC_ROUTES = [
  {
    path: "/",
    component: Landing,
    id: "/",
    exact: true,
  },
  {
    path: "/login",
    component: Login,
    id: "/login",
    exact: true,
  },
  {
    path: "/terms-and-conditions",
    component: TermsAndConditions,
    id: "/termsAndConditions",
    exact: true,
  },
  {
    path: "/privacy-policy",
    component: PrivacyPolicy,
    id: "/privacyPolicy",
    exact: true,
  },
  ...PUBLIC_PRIVATE_ROUTES,
];

export const ROUTES_WITHOUT_HEADER = [
  "/",
  "/login",
  "/about",
  // "/termsAndConditions/memberAgreement",
  // "/termsAndConditions/leadTerms",
  // "/termsAndConditions/guidelines",
  // "/termsAndConditions/advertisingTerms",
  "/about/neighbourly",
  "/about/whatIsNeighbourly",
  "/about/ourMissionStatement",
  "/about/privacy",
  "/about/safety",
  "/about/guidelines",
  "/about/backstory",
  "/termsAndConditions",
  "/privacyPolicy",
];
