/* eslint-disable react/jsx-indent */
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { notification, Divider } from "antd";
import { Link } from "react-router-dom";
import { slugify } from "../../../utils/helpers";
// components
import NbVerticalSideMenu from "../../../assets/gobal/components/nbVertocalSideMenu/nbVerticalSideMenu";

import { getMenuLists } from "./homeSiderMenuLists";
import { ReactComponent as CommunityFullLogo } from "../../../assets/images/fulllogo.svg";
import { ReactComponent as Logout } from "../../../assets/icons/logout.svg";

// hooks
import { useNbRoutes, useProfileSwitchingGuard } from "../../../utils";
// actions
import tokenActions from "../../../store/auth/action";
import { ALL_POSTS_REMOVE } from "../../../store/constants";

// apis
import authApis from "../../../api/services/auth/auth";
// scss
import "../../../assets/gobal/components/nbVertocalSideMenu/nbVerticalSideMenu.scss";
import { Home } from "../../../assets/icons/sider";

function HomeSider() {
  const me = useSelector((state) => state.me);
  const switchingGuard = useProfileSwitchingGuard();

  const { menuList1, menuListForAllVerified } = getMenuLists();

  const [activeMenuItem, setActiveMenuItem] = useState(menuList1[0]?.key);

  const getFormattedMenuListCommunities = (communities) => {
    return communities?.map((community) => {
      const communitySlug = slugify(community.name);
      return {
        key: community.neighbourhood_id,
        label: community.name,
        to: `/home/${communitySlug}/${community.neighbourhood_id}`,
        icon: "",
      };
    });
  };

  const dispatch = useDispatch();
  const nbRoutes = useNbRoutes();
  const logout = async () => {
    try {
      await authApis.userLogout();
      dispatch(tokenActions.Token(null));
      dispatch(tokenActions.clearMe());
      window.localStorage.removeItem("signupAuthToken");
      if (nbRoutes.private) {
        nbRoutes.gotoLogin();
      }
      dispatch({
        type: ALL_POSTS_REMOVE,
      });
    } catch (err) {
      notification.error({
        message: err?.message || "Something went wrong!",
      });
    }
  };

  return (
    <>
      <Link className="custom-logo ml6" to="/home/all">
        <CommunityFullLogo />
      </Link>
      <Divider style={{ margin: "0.3rem  1rem 2rem 0" }} />
      {switchingGuard.isUser() && (
        <>
          <div className="nb-vertical-side-menu home">
            <div className="nb-vertical-side-menu-item mainSider">
              <Link to="#" className="nb-base-text px3  ">
                <Home className="mr2 w25" />
                Home
              </Link>
            </div>
          </div>
          {menuListForAllVerified.map((menuListItem, i) => {
            return (
              <NbVerticalSideMenu
                key={menuListItem.menuList.title}
                title={menuListItem.menuList.title}
                onItemClick={(item) => setActiveMenuItem(item.key)}
                active={activeMenuItem}
                menuList={menuListItem.menuList?.list}
                moreMenuList={menuListItem.menuList?.moreList}
                className="mainSider"
              />
            );
          })}
          {/* communities for users of verified user */}
          {me.communities?.length > 0 && (
            <div className="nb-comunities-container">
              <NbVerticalSideMenu
                title=""
                onItemClick={(item) => setActiveMenuItem(item.key)}
                active={activeMenuItem}
                menuList={getFormattedMenuListCommunities(me.communities)}
                className="communities"
              />
            </div>
          )}
          {me.is_login && (
            <div className="nb-vertical-side-menu ">
              <div className="nb-vertical-side-menu-item logout">
                <Link
                  to="#"
                  className="nb-base-text px3  "
                  onClick={() => logout()}
                >
                  <Logout />
                  Logout
                </Link>
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
}

export default HomeSider;
