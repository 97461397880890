export const formatPrice = (priceString) => {
  const priceFormatter = new Intl.NumberFormat("en", {
    style: "currency",
    currency: "USD",
  });
  return priceFormatter.format(priceString);
};

export const formatNumber = (priceString) => {
  const priceFormatter = new Intl.NumberFormat();
  return priceFormatter.format(priceString);
};
