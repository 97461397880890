import React from "react";
import { Link, useHistory } from "react-router-dom";
import PropTypes from "prop-types";

// components

// icons
import { LockRoundIcon } from "../../../icons/sider";

// scss
import "./nbVerticalSideMenu.scss";
import { slugify } from "../../../../utils";

function MenuItem({
  className,
  menuItem,
  onClick,
  onKeyDown,
  itemClassName,
  primary,
  borderSimple,
}) {
  const { icon: Icon, disabled } = menuItem;
  const history = useHistory();

  let IconLocal = null;

  if (disabled) {
    IconLocal = <LockRoundIcon className="mr2 w2 side-menu-lock-icon" />;
  } else if (typeof Icon === "string") {
    IconLocal = <img className="mr2 w25" src={Icon} alt="" />;
  } else if (typeof Icon === "object") {
    IconLocal = Icon && <Icon className="mr2 w25" />;
  }
  let menuData = menuItem.label;

  if (menuData.toLowerCase() === "all activities") {
    menuData = "all";
  }
  let isSelected = false;
  const paths = history.location.pathname.split("/").slice(0, 3);
  paths.forEach((slug) => {
    if (!isSelected && slug.trim()) {
      isSelected = slugify(menuData.toLowerCase()).includes(slug.toLowerCase());
    }
  });

  return (
    <div
      className={`nb-vertical-side-menu-item ${className} ${
        // isSelected || active === menuItem.key
        isSelected ? `nb-vertical-side-menu-item-active` : ""
      } ${primary ? "nb-vertical-side-menu-item-primary" : ""} ${
        borderSimple ? "nb-vertical-side-menu-item-border-simple" : ""
      }`}
      onClick={onClick}
      onKeyDown={onKeyDown}
      role="button"
      tabIndex="0"
    >
      <Link
        to={menuItem.to ? menuItem.to : "#"}
        className={`nb-base-text px3 ${itemClassName ?? ""} ${
          primary ? "nb-vertical-side-menu-text-primary" : ""
        }`}
      >
        {IconLocal}
        {menuItem.label}
      </Link>
    </div>
  );
}

function GuardedMenuItem({
  menuItem,
  index,
  onItemClick,
  itemClassName,
  primary,
  borderSimple,
  className,
}) {
  const { disabled } = menuItem;

  return disabled ? (
    <MenuItem
      menuItem={menuItem}
      onClick={() => onItemClick(menuItem, index)}
      onKeyDown={(event) => {
        if (event.key === "Enter") onItemClick(menuItem, index);
      }}
      className="menu-item-for-lite-user"
      primary={primary}
      borderSimple={borderSimple}
      itemClassName={itemClassName}
    />
  ) : (
    <MenuItem
      menuItem={menuItem}
      onClick={() => onItemClick(menuItem, index)}
      onKeyDown={(event) => {
        if (event.key === "Enter") onItemClick(menuItem, index);
      }}
      primary={primary}
      borderSimple={borderSimple}
      itemClassName={itemClassName}
      className={`menu-item-${index} ${className}`}
    />
  );
}

function NbVerticalSideMenu(props) {
  const {
    menuList,
    onItemClick,
    title,
    itemClassName,
    primary,
    borderSimple,
    className,
  } = props;

  return (
    <div className="nb-vertical-side-menu ">
      {title && <div className="nb-faded-text fn8 fnw-600 mb1">{title}</div>}
      {menuList?.map((menuItem, index) => {
        return (
          <GuardedMenuItem
            key={menuItem.to}
            menuItem={menuItem}
            index={index}
            primary={primary}
            borderSimple={borderSimple}
            itemClassName={itemClassName}
            className={className}
            onItemClick={onItemClick}
          />
        );
      })}
    </div>
  );
}

export default NbVerticalSideMenu;

NbVerticalSideMenu.defaultProps = {
  title: "",
  // moreMenuList: undefined,
};

NbVerticalSideMenu.propTypes = {
  menuList: PropTypes.arrayOf(PropTypes.object).isRequired,
  // moreMenuList: PropTypes.arrayOf(PropTypes.object),
  onItemClick: PropTypes.func.isRequired,
  // active: PropTypes.string.isRequired,
  title: PropTypes.string,
};
