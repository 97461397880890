import React, { useState } from "react";
import { DownloadOutlined } from "@ant-design/icons";
import { Button, Modal } from "antd";
import { DocIcon, PdfIcon } from "../../../../assets/icons/post";
import NbDivider from "../../../../assets/gobal/components/nbDivider/nbDivider";
import { getExtentionFromUrl } from "../../../../utils";

function DocumentItemIcon({ url }) {
  const extention = getExtentionFromUrl(url);
  switch (extention) {
    case "pdf":
      return <img src={PdfIcon.default} alt="" />;
    case "doc":
    case "docx":
      return <img src={DocIcon.default} alt="" />;
    default:
      return <img src={PdfIcon.default} alt="" />;
  }
}

function DocumentItem({ data, className }) {
  return (
    <div className={`post-media-document-grid-item ${className}`}>
      <div className="display-flex align-items-center justify-content-space-between w-p100">
        <DocumentItemIcon url={data?.url} />
        <p className="truncate">{data?.name}</p>
        <a href={data.url} download={data.url.split("/").pop()}>
          <DownloadOutlined 
            className="nb-default-icon"
            // style={{ fontSize: "1.5rem" }} 
          />
        </a>
      </div>
    </div>
  );
}

function DocumentContent({ pdf }) {
  const [isShowAllDocuments, setShowAllDocuments] = useState(false);

  return (
    <>
      {pdf.length !== 0 && (
        <div className="post-media-document-grid">
          {pdf.slice(0, 2).map((item) => {
            return <DocumentItem key={`pdf-file-${item.url}`} data={item} />;
          })}
        </div>
      )}
      {pdf.length > 2 && (
        <div className="post-media-document-view-more">
          <Button onClick={() => setShowAllDocuments(true)}>
            View All Documents
          </Button>
        </div>
      )}
      <Modal
        className="document-list-modal"
        title={
          <div>
            <h4 className="mb0">Documets Attached</h4>
            <p className="mb0 fn8 fnw-400 nb-faded-text">
              {`Total 
            ${pdf.length}
             files`}
            </p>
          </div>
        }
        footer={null}
        centered
        onCancel={() => setShowAllDocuments(false)}
        open={isShowAllDocuments}
      >
        <div className="document-list-modal-body nb-nice-scroll-style">
          {pdf?.map((item) => {
            return (
              <div
                key={`pdf-file-${item.url}`}
                className="document-list-document-wrapper"
              >
                <DocumentItem className="p4" data={item} />
                <NbDivider />
              </div>
            );
          })}
        </div>
      </Modal>
    </>
  );
}

export default DocumentContent;
