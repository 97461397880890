import React from "react";
import { Spin } from "antd";

// scss
import "./fallbackLoader.scss";

function FallbackLoader({ containerClassName }) {
  return (
    <div className={`nb-lazy-load-fallback ${containerClassName ?? ""}`}>
      <Spin size="large" />
    </div>
  );
}

export default FallbackLoader;
