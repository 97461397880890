import React, { useState } from "react";
import { Modal, Radio, Button } from "antd";
import PropTypes from "prop-types";

function ReportPostModal({
  title,
  visible,
  onOk,
  onCancel,
  destroyOnClose,
  loading,
  hideOptions,
}) {
  const reportCases = [
    "Inappropriate",
    "Abusive",
    "Commercial",
    "Repeated post",
  ].filter((reportCase) => !hideOptions.includes(reportCase));

  const [report, setReport] = useState(reportCases[0]);

  return (
    <Modal
      title={title}
      open={visible}
      onOk={onOk}
      onCancel={onCancel}
      destroyOnClose={destroyOnClose}
      footer={[
        <Button className="nb-button" key="back" onClick={onCancel}>
          Cancel
        </Button>,
        <Button
          key="submit"
          type="primary"
          loading={loading}
          className="nb-button"
          onClick={() => onOk(report)}
        >
          Submit
        </Button>,
      ]}
    >
      <p>This post is</p>
      <Radio.Group
        onChange={(e) => setReport(e.target.value)}
        defaultValue="Inappropriate"
      >
        {reportCases.map((value) => {
          return (
            <Radio
              key={`report-cases-${value}`}
              className="d-block mb2"
              value={value}
            >
              {value}
            </Radio>
          );
        })}
      </Radio.Group>
    </Modal>
  );
}

export default ReportPostModal;

ReportPostModal.defaultProps = {
  title: "Report this post",
  onOk: () => null,
  onCancel: () => null,
  destroyOnClose: true,
  loading: false,
  hideOptions: [],
};

ReportPostModal.propTypes = {
  title: PropTypes.string,
  onOk: PropTypes.func,
  onCancel: PropTypes.func,
  visible: PropTypes.bool.isRequired,
  destroyOnClose: PropTypes.bool,
  loading: PropTypes.bool,
  hideOptions: PropTypes.arrayOf(PropTypes.string),
};
