import { Get, Put } from "../../requests";

const getNotificationListing = (page = 1) => {
  return Get(`/users/notifications?page=${page}`);
};

const readNotification = (noticationId, params) => {
  return Put(`/users/notifications/${noticationId}`, params);
};

const NotificationsService = {
  getNotificationListing,
  readNotification,
};

export default NotificationsService;
