import React from "react";
import PropTypes from "prop-types";
import { Dropdown } from "antd";

// components
import PostHeadInfo from "../postHeadInfo/postHeadInfo";
import AuthGuard from "../../authGuard/authGuard";

// icons
import NbMoreButton from "../../../assets/gobal/components/nbMoreButton/nbMoreButton";

function PostHead({
  style,
  className,
  name,
  imageUrl,
  isPublic,
  createdAt,
  actionMenu,
  isEdited,
  userId,
  data,
}) {
  return (
    <div className={`post-head mb2 display-flex ${className}`} style={style}>
      <div className="flex1">
        <PostHeadInfo
          name={name}
          imageUrl={imageUrl}
          isPublic={isPublic}
          userId={userId}
          data={data}
        />
      </div>
      <div>
        <div className="display-flex align-items-center">
          {createdAt && (
            <span className="fn8 nb-faded-text">
              {createdAt}
              {isEdited ? " (edited)" : ""}
            </span>
          )}
          <AuthGuard
            authActions={{
              public: {
                action: "HIDE",
              },
              not_verified: {
                action: "HIDE",
              },
            }}
          >
            {actionMenu && (
              <Dropdown
                overlay={actionMenu}
                placement="bottomRight"
                trigger="click"
                overlayStyle={{ width: "200px", zIndex: 9 }}
                overlayClassName="dropdown-wrapper"
              >
                <div>
                  <NbMoreButton className="ml1" />
                </div>
              </Dropdown>
            )}
          </AuthGuard>
        </div>
      </div>
    </div>
  );
}

export default PostHead;

PostHead.defaultProps = {
  style: {},
  className: "",
  isPublic: false,
  createdAt: undefined,
  actionMenu: undefined,
  imageUrl: "",
};

PostHead.propTypes = {
  style: PropTypes.objectOf(PropTypes.object),
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
  imageUrl: PropTypes.string,
  isPublic: PropTypes.bool,
  createdAt: PropTypes.string,
  actionMenu: PropTypes.element,
};
