import React from "react";
import { useSelector } from "react-redux";
import { Avatar } from "antd";
import { UserOutlined } from "@ant-design/icons";

// components
import SideIcon from "../../../../assets/gobal/components/sideIcon";

// hooks

export default function PostingAs({
  isCustom,
  normText,
  boldText,
  containerClass,
  showRightIcon,
  rightIcon,
  rightIconContainerClassName,
  rightIconClass,
  onClick,
}) {
  const {
    first_name: firstName,
    last_name: lastName,
    profile_image: profileImage,
    default_display: defaultDisplay,
  } = useSelector((state) => state.me || {});

  if (isCustom) {
    return (
      <div
        className={`posting-as-container justify-content-space-between ${
          containerClass ?? ""
        }`}
        role="toolbar"
        onClick={onClick}
        onKeyDown={onClick}
      >
        <p className="posting-as-text">
          {normText}
          <span className="text-primary posting-as-text-primary fnw-bold">
            {boldText}
          </span>
        </p>

        {showRightIcon && (
          <SideIcon
            Icon={rightIcon}
            containerClassName={rightIconContainerClassName}
            iconClassName={rightIconClass}
          />
        )}
      </div>
    );
  }

  return (
    <div className="posting-as-container">
      <p className="posting-as-text">
        <span>Posting as</span>
        <Avatar
          size="small"
          icon={<UserOutlined />}
          src={profileImage}
          className="profiles-menu-avatar ml2"
        />
        <span className="text-primary posting-as-text-primary fnw-bold">
          {defaultDisplay?.name
            ? defaultDisplay.name
            : `${firstName} ${lastName}`}
        </span>
        from communities
      </p>
    </div>
  );
}
