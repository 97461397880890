import React from "react";
import { Upload, notification } from "antd";

// components
import JourneyOptionBtn from "./journeyOptionBtn";
import { ImageIcon } from "../../../assets/icons/createPost";
import { uploadAccept } from "../../../utils/helpers";

// utils
import { useMediaUploader } from "../../../utils";
import Info from "../../../assets/gobal/components/Info/Info";
import config from "../../../config";

export default function JourneyImageUploader(props) {
  const { files, onUpload, onDelete } = props;

  const mediaUploader = useMediaUploader();

  const uploadDisabled = false;

  /* istanbul ignore next */
  const customRequest = ({ onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  /* istanbul ignore next */
  const deleteImg = async (data) => {
    let updateFilesList = [...files];
    const filesToDelete = updateFilesList.filter(
      (file) => file.uid === data.uid
    );
    if (filesToDelete.length) {
      try {
        await mediaUploader.deleteMedia(filesToDelete[0].uid);
        updateFilesList = updateFilesList.filter(
          (file) => file.uid !== data.uid
        );
        onDelete(updateFilesList);
      } catch (error) {
        notification.error({ message: error.message, duration: 5 });
      }
    }
  };

  /* istanbul ignore next */
  const handleChange = ({ fileList }) => {
    onUpload(fileList);
  };

  const uploadProps = {
    customRequest,
    multiple: true,
    maxCount: 10,
    className: "journey-upload-container",
    onChange: handleChange,
    showUploadList: false,
    accept: uploadAccept,
  };

  return (
    <>
      <Upload {...uploadProps} fileList={files} disabled={uploadDisabled}>
        <JourneyOptionBtn
          title="Add Image/Doc"
          leftIcon={ImageIcon}
          leftIconClass="fill-primary"
          showRightIcon={false}
          disabled={uploadDisabled}
        />
      </Upload>
      <Info
        title={`Max upload size is ${config.uploadSize}`}
        style={{ margin: "0px 15px" }}
      />
      {files?.length ? (
        <div className="px7 pt7 pb4 apply-bottom-border">
          <Upload
            customRequest={customRequest}
            listType="picture-card"
            fileList={files}
            onRemove={deleteImg}
            showUploadList={{
              showPreviewIcon: false,
            }}
            disabled={uploadDisabled}
          />
        </div>
      ) : null}
    </>
  );
}
