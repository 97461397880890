import React from "react";
import PropTypes from "prop-types";
// scss
import "./Info.scss";
import infoIcon from "../../../icons/info.svg";

function Info(props) {
  const { title, style, className } = props;
  return (
    <div className={`info-container ${className}`} style={style}>
      <img src={infoIcon} alt="" />
      <span>{title}</span>
    </div>
  );
}

export default Info;

Info.defaultProps = {
  title: undefined,
  style: {},
  className: "",
};

Info.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  style: PropTypes.objectOf(PropTypes.object),
  className: PropTypes.string,
};
