import React, { useRef, useContext, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Avatar, Dropdown, Menu, Badge, Button } from "antd";

// components
import ReactionButtons from "../reactionButtons/reactionButtons";
import PostCommentForm from "../postCommentForm/postCommentForm";
import Portal from "../../../assets/gobal/components/portal/portal";

// icons
import { More } from "../../../assets/icons";
// scss
import "./comment.scss";

// formatters
import { formattedFromNow } from "../../../utils";

// context
import { PostCommentsContext } from "../postComments/context";

// icons
import { Comment as CommentIcon } from "../../../assets/icons/post";
import MetaTagsContent from "../metaTagsContent/metaTagsContent";

function Comment({
  index,
  style,
  className,
  children,
  comment: commentData,
  onReactComment,
  onUnReactComment,
  onClickComment,
  onReplyComment,
  onDeleteComment,
  onViewMoreReply,
  parentId,
  isReply,
  isShowViewMoreReplies,
  pagination,
  me,
  commentsDisabled,
  onReportComment,
}) {
  const {
    body,
    user,
    created_at: createdAt,
    comment_id: commentId,
    isReplyFormLoading,
    apiResponse,
    is_liked: isLiked,
    likes_count: likesCount,
    media,
    unique_reacts: uniqueReact,
    meta_tags: metaTags,
  } = commentData;
  const SHOW_MORE_LIMIT = 300;
  const ref = useRef(null);
  const [showMore, setShowMore] = useState(body?.length < SHOW_MORE_LIMIT);
  const { activeCommetFormIndex, setActiveCommentForm } =
    useContext(PostCommentsContext);

  const isAuthor = me?.user_id === user?.user_id;
  const avatarName = `${user?.first_name
    ?.charAt(0)
    ?.toUpperCase()}${user?.last_name?.charAt(0)?.toUpperCase()}`;

  if (!commentData) {
    return null;
  }

  return (
    <div
      className={`post-comment display-flex ${className}`}
      style={style}
      ref={ref}
    >
      <div className="mr4">
        {user?.profile_image ? (
          <Badge
            size="default"
            style={{ backgroundColor: "#cb46ff" }}
            text
            offset={[-12, 5]}
          >
            <Avatar src={user?.profile_image} size={42} />
          </Badge>
        ) : (
          <Badge
            size="default"
            style={{ backgroundColor: "#cb46ff" }}
            text
            offset={[-12, 5]}
          >
            <Avatar
              style={{
                backgroundColor: "#f56a00",
                marginRight: "5px",
              }}
              size="large"
            >
              {avatarName}
            </Avatar>
          </Badge>
        )}
      </div>
      <div className="post-comment-right display-flex flex1 flex-column">
        <div>
          <div className="post-comment-right-comment-content display-flex px4 pt1 pb2 flex1 mb2">
            <div className="flex1">
              <div className="display-flex align-items-center justify-content-space-between">
                <p className="display-flex align-items-center fn9 fnw-600">
                  {`${user?.first_name} ${user?.last_name}`}
                </p>
                <div className="display-flex mt1 justify-content-flex-end">
                  <span className="fn6 nb-faded-text">
                    {formattedFromNow(createdAt)}
                  </span>
                  <Dropdown
                    overlay={
                      <Menu>
                        {isAuthor && (
                          <Menu.Item>
                            <button
                              onClick={() =>
                                onDeleteComment(
                                  // parentId || commentId,
                                  // commentId
                                  commentId
                                )
                              }
                              type="button"
                              className="nb-proxy-button"
                            >
                              Delete
                            </button>
                          </Menu.Item>
                        )}
                        {!isAuthor && (
                          <Menu.Item>
                            <button
                              onClick={() =>
                                onReportComment(
                                  parentId || commentId,
                                  commentId
                                )
                              }
                              type="button"
                              className="nb-proxy-button"
                            >
                              Report
                            </button>
                          </Menu.Item>
                        )}
                      </Menu>
                    }
                    placement="bottomRight"
                    trigger="click"
                  >
                    <More className="ml3 c-pointer" height="12" />
                  </Dropdown>
                </div>
              </div>
              {/* <p className="fn7">
                <span className="nb-faded-text mr1">from</span>
                <Link to="">{user?.neighbourhood?.name}</Link>
              </p> */}
              <p className="o-wrap-any">
                {showMore || body === undefined
                  ? body
                  : `${body?.substring(0, SHOW_MORE_LIMIT)}...`}
                {!showMore && body?.length > SHOW_MORE_LIMIT && (
                  <Button
                    type="link"
                    onClick={() => {
                      setShowMore(true);
                    }}
                  >
                    show more
                  </Button>
                )}
                {showMore && body?.length > SHOW_MORE_LIMIT && (
                  <Button
                    type="link"
                    onClick={() => {
                      setShowMore(false);
                      window.scroll({
                        top: ref.current.offsetTop - 300,
                        behavior: "smooth",
                      });
                    }}
                  >
                    show less
                  </Button>
                )}
              </p>
              {media && (
                <div className="comment-media mxw200 mt2">
                  <a href={media.url} target="_blank" rel="noreferrer">
                    <img className="w-p100" src={media.url} alt="" />
                  </a>
                </div>
              )}
              {metaTags && <MetaTagsContent metaTags={metaTags} />}
            </div>
          </div>
          <ReactionButtons
            showComment={!isReply && !commentsDisabled}
            onReact={(reactionKey) => onReactComment(reactionKey, commentId)}
            isLiked={isLiked}
            likesCount={likesCount}
            uniqueReacts={uniqueReact}
            onUnReact={(reactionKey) =>
              onUnReactComment(reactionKey, commentId)
            }
            commentButton={
              <div className="nb-faded-text fn8 ml4 c-pointer">
                <button
                  type="button"
                  onClick={() => setActiveCommentForm(index)}
                  className={` nb-proxy-button  ${
                    commentsDisabled ? "c-not-allowed" : "c-pointer"
                  }  `}
                  title={commentsDisabled ? "Replies has been Disabled" : ""}
                >
                  <CommentIcon className="post-reaction-button-icons mr1" />
                  <span className=""> Reply</span>
                </button>
              </div>
            }
          />
        </div>

        <div className="replies mt4">{children}</div>
        {activeCommetFormIndex === index &&
          !isReply &&
          // showReply &&
          !commentsDisabled && (
            <Portal mountNode={ref?.current?.children[1]}>
              <PostCommentForm
                placeholder="Add your reply"
                className="mb4"
                style={{ marginTop: ".25rem" }}
                showMessage={isReply}
                isLoading={isReplyFormLoading}
                apiResponse={apiResponse}
                onComment={(commentReplyData) => {
                  onReplyComment(commentReplyData, parentId || commentId);
                }}
              />
            </Portal>
          )}
      </div>
    </div>
  );
}

export default Comment;

Comment.defaultProps = {
  style: {},
  className: "",
  children: [],
  isReply: false,
  onViewMoreReply: undefined,
  pagination: undefined,
};

Comment.propTypes = {
  style: PropTypes.objectOf(PropTypes.object),
  className: PropTypes.string,
  children: PropTypes.arrayOf(PropTypes.element),
  comment: PropTypes.instanceOf(PropTypes.object).isRequired,
  onReactComment: PropTypes.func.isRequired,
  onUnReactComment: PropTypes.func.isRequired,
  onClickComment: PropTypes.func.isRequired,
  onReplyComment: PropTypes.func.isRequired,
  onDeleteComment: PropTypes.func.isRequired,
  onViewMoreReply: PropTypes.func,
  isReply: PropTypes.bool,
  parentId: PropTypes.string.isRequired,
  isShowViewMoreReplies: PropTypes.bool.isRequired,
  pagination: PropTypes.instanceOf(PropTypes.object),
  me: PropTypes.instanceOf(PropTypes.object).isRequired,
  commentsDisabled: PropTypes.bool.isRequired,
  onReportComment: PropTypes.bool.isRequired,
  index: PropTypes.string.isRequired,
};
