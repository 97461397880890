import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Post from "../../components/post/post";
import postApis from "../../api/services/post/post";
import Container from "../../assets/gobal/components/container/container";
import ListingLayout from "../../assets/gobal/components/listingLayout/listingLayout";
import { PostSkeleton } from "../../assets/gobal/components/nbSkeleton";
import HomeSider from "../home/homeSider/homeSider";
import EditPostModal from "../../assets/gobal/components/editPostModal";
import Header from "../../assets/gobal/components/header/header";

import "../postListing/postListing.scss";

export default function PostView() {
  const [isViewPostLoading, setIsViewPostLoading] = useState(false);
  const [editPost, setEditPost] = useState({ status: false, data: null });
  const [postData, setPostData] = useState(null);
  const params = useParams();

  /* istanbul ignore next */
  const handleEditPost = (editPostData) => {
    setEditPost({ status: true, data: editPostData });
  };
  useEffect(() => {
    /* istanbul ignore next */
    (async () => {
      try {
        const res = await postApis.getPostById(params?.id);
        setPostData(res?.data);
      } catch (error) {
        setIsViewPostLoading(false);
        console.log(error);
      }
    })();
  }, [editPost]);

  useEffect(() => {
    /* istanbul ignore next */
    (async () => {
      try {
        setIsViewPostLoading(true);
        const res = await postApis.getPostById(params?.id);
        setPostData(res?.data);
        setIsViewPostLoading(false);
      } catch (error) {
        setIsViewPostLoading(false);
        console.log(error);
      }
    })();
  }, []);

  /* istanbul ignore next */
  const closeEditModal = () => setEditPost({ status: false, data: null });
  return (
    <Container className="home-container nb-full-screen" fluid>
      <ListingLayout
        className="home-listing-layout"
        header={<Header />}
        rightSider={{
          component: <div className="nb-nice-scroll-style" />,
        }}
        leftSider={{
          component: <HomeSider />,
        }}
      >
        <div className="post-listing-content py6">
          {
            /* istanbul ignore next */
            isViewPostLoading && <PostSkeleton loading className="p4 mb4" />
          }
          {
            /* istanbul ignore next */
            postData && (
              <Post
                // type={
                //   postData?.post_category?.parent_slug ||
                //   postData?.post_category?.slug ||
                //   POST_TYPES.post_sticky
                // }
                data={postData}
                handleEditPost={handleEditPost}
              />
            )
          }
        </div>
        <EditPostModal
          // title={
          //   editPost?.data?.post_category?.parent_slug ||
          //   editPost?.data?.post_category?.name
          // }
          title="Message"
          visible={editPost.status}
          onCancel={closeEditModal}
          postData={editPost}
          slug={
            editPost?.data?.post_category?.parent_slug ||
            editPost?.data?.post_category?.slug
          }
        />
      </ListingLayout>
    </Container>
  );
}
