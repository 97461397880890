import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

// components

// scss
import "./postHeadInfo.scss";

// icons
import NbAvatar from "../../../assets/gobal/components/nbAvatar/nbAvatar";

function PostHeadInfo({
  style,
  className,
  name,
  imageUrl,
  alternateInfo,
  avatarSize,
  userId,
  data,
}) {
  const profileRedidrection = () => {
    if (data?.post_owner === "user") {
      return (
        <Link
          to={`/profile/${userId}`}
          from={{ otherUser: true }}
          className="display-flex align-items-center m0 nb-base-text w-fc"
        >
          {name}
        </Link>
      );
    }
    return name;
  };

  return (
    <div
      className={`post-head-info display-flex post-header-details ${className}`}
      style={style}
    >
      <NbAvatar
        profileImage={imageUrl}
        size={avatarSize}
        firstName={data?.owner?.first_name || data?.first_name}
        lastName={data?.owner?.last_name || data?.last_name}
      />
      <div className="display-flex align-items-center flex1 post-head-title">
        <div className="flex1">
          {profileRedidrection()}
          <p className="fn7 mb0 description display-flex align-items-center">
            {alternateInfo}
          </p>
        </div>
      </div>
    </div>
  );
}

export default PostHeadInfo;

PostHeadInfo.defaultProps = {
  style: {},
  className: "",
  alternateInfo: undefined,
  avatarSize: 56,
  imageUrl: " ",
};

PostHeadInfo.propTypes = {
  style: PropTypes.objectOf(PropTypes.object),
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
  imageUrl: PropTypes.string,
  alternateInfo: PropTypes.element,
  avatarSize: PropTypes.number,
};
