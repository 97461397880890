import React, { useState } from "react";
import { Button } from "antd";
import PropTypes from "prop-types";
import { SavedIcon, SaveIcon } from "../../../icons/post/index";

// scss
import "./postSaveButton.scss";

function PostSaveButton({
  save,
  className,
  onSave,
  onUnSave,
  onClick,
  disabled,
}) {
  const [saveLocal, setSaveLocal] = useState(save);
  return (
    <Button
      className={`post-actions-save-buttons fn7 ${className}`}
      type="link"
      disabled={disabled}
      title="Save post"
      onClick={() => {
        if (onClick) {
          onClick();
        } else if (saveLocal) {
          onUnSave?.();
          setSaveLocal(!saveLocal);
        } else {
          onSave?.();
          setSaveLocal(!saveLocal);
        }
      }}
    >
      {saveLocal ? <SavedIcon className="fill" /> : <SaveIcon />}
    </Button>
  );
}

export default PostSaveButton;

PostSaveButton.defaultProps = {
  className: "",
  save: false,
  onSave: undefined,
  onUnSave: undefined,
  onClick: undefined,
};

PostSaveButton.propTypes = {
  className: PropTypes.string,
  save: PropTypes.bool,
  onSave: PropTypes.func,
  onUnSave: PropTypes.func,
  onClick: PropTypes.func,
};
