/* eslint-disable default-param-last */
import { TOGGLE_PROGRESS_LOADING, SHOW_HIDE_LOGIN_MODAL } from "../constants";

const initialState = {
  progressLoading: {
    isAnimating: false,
    key: "0",
  },
  loginModal: {
    visible: false,
  },
};

export function globalComponentsReducer(state = initialState, action) {
  switch (action.type) {
    case TOGGLE_PROGRESS_LOADING: {
      return {
        ...state,
        progressLoading: {
          isAnimating: action.payload,
          key: action.payload ? "1" : "0",
        },
      };
    }
    case SHOW_HIDE_LOGIN_MODAL: {
      return {
        ...state,
        loginModal: {
          visible: action.payload,
        },
      };
    }
    default:
      return state;
  }
}
