import React from "react";
import LoginModal from "./components/loginModal/loginModal";

// components
import Routes from "./routes";

function App() {
  return (
    <>
      <Routes />
      <LoginModal />
    </>
  );
}

export default App;
