import axios from "axios";
import config from "../config";

const baseURL = `${config.baseURL}${
  config.version ? `/${config.version}` : ""
}`;

const service = axios.create({
  baseURL,
  headers: { Accept: "application/json" },
});

service?.interceptors?.response.use(
  async (res) => {
    return res?.data;
  },
  async (err) => {
    return Promise.reject(err?.response?.data);
  }
);

service?.interceptors?.request.use((req) => {
  const token = localStorage.getItem("signupAuthToken");
  if (token) {
    req.headers = {
      Authorization: `Bearer ${token}`,
      ...req.headers,
    };
  }
  return req;
});

export default service;
