import React from "react";
import { Button } from "antd";
import { BackIcon } from "../../../icons/createPost";

export default function CardHeader(props) {
  const {
    title,
    IconComp,
    onBackBtnClick,
    onDoneClick,
    btnDisabled,
    iconClassName,
  } = props;

  return (
    <div className="display-flex align-items-center card-header bg-light-grey">
      <div className="back-icon-container">
        <BackIcon className="back-icon" onClick={onBackBtnClick} />
      </div>
      <div className="title-container">
        <IconComp className={`icon ${iconClassName ?? ""}`} />
        <p className="title">{title}</p>
      </div>
      <div className="next-btn-container">
        <Button
          type="primary"
          className={btnDisabled ? "btn-disabled" : ""}
          onClick={onDoneClick}
          disabled={btnDisabled}
        >
          Done
        </Button>
      </div>
    </div>
  );
}
