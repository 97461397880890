import React, { useState, useEffect } from "react";
import { Avatar, Menu } from "antd";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import NotificationsService from "../../../../api/services/notifications/notification";
import {
  getInitialChars,
  formattedFromNow,
  getEchoInstance,
} from "../../../../utils";
import NbDivider from "../nbDivider/nbDivider";
import NotificationLoading from "./notificationLoading";

function NotificationListing(props) {
  const { unreadCount, setUnReadCount } = props;
  const history = useHistory();
  const me = useSelector((state) => state.me);
  const [notificationListing, setNotificationListing] = useState({});
  const [isNotification, setIsNotification] = useState(true);
  const [notificationLoading, setNotificationLoading] = useState(true);

  const [pagination, setPagination] = useState("");

  const getNotificationListing = async (page) => {
    try {
      setNotificationLoading(true);
      const res = await NotificationsService.getNotificationListing(page);
      if (res?.data?.data?.length <= 0) {
        setIsNotification(false);
        setNotificationLoading(false);
      }
      setUnReadCount(res.data.unread_count);
      setNotificationListing((prev) => ({ ...prev, ...res?.data }));
      setPagination(res.data);

      const finalResponse = {
        ...notificationListing,
        ...res.data,
        data: [
          ...(notificationListing?.data || []),
          ...(res?.data?.data || []),
        ],
      };
      setNotificationListing(finalResponse);
      setNotificationLoading(false);
    } catch (error) {
      setNotificationLoading(false);
    }
  };
  const handleUpdateMessagesList = () => {
    getNotificationListing();
  };

  const echo = getEchoInstance();
  useEffect(() => {
    getNotificationListing();
    echo
      .private(`notification-${me?.user_id}`)
      .listen(".new-notification", handleUpdateMessagesList);
  }, []);

  const getName = (user) => {
    return getInitialChars(`${user?.first_name}  ${user?.last_name}`);
  };

  const NotificationDetails = async (data) => {
    // eslint-disable-next-line camelcase
    const { notification_id, entity_type, entity_id } = data;
    const isRead = data.is_read;

    try {
      await NotificationsService.readNotification(notification_id, {
        action: "read",
      });

      const updatedNotificationListing = notificationListing.data.map((noc) => {
        // eslint-disable-next-line camelcase
        if (noc.notification_id === notification_id) {
          return { ...noc, is_read: true };
        }
        return noc;
      });
      setNotificationListing({
        ...notificationListing,
        data: updatedNotificationListing,
      });

      let currentCount;
      if (unreadCount > 0 && isRead === true) {
        currentCount = unreadCount;
      } else if (unreadCount > 0 && isRead === false) {
        currentCount = unreadCount - 1;
      } else {
        currentCount = 0;
      }

      setUnReadCount(currentCount);
      // eslint-disable-next-line camelcase
      if (entity_type === "post" || entity_type === "comment") {
        // eslint-disable-next-line camelcase
        history.push(`/post/view/${entity_id}`);
        // eslint-disable-next-line camelcase
      } else if (entity_type === "user") {
        // eslint-disable-next-line camelcase
        history.push(`/profile/${entity_id}`);
        // eslint-disable-next-line camelcase
      } else if (entity_type === "business") {
        // eslint-disable-next-line camelcase
        history.push(`/business/${entity_id}/details`);
        // eslint-disable-next-line camelcase
      } else if (entity_type === "organisation") {
        // eslint-disable-next-line camelcase
        history.push(`/organisation/${entity_id}`);
        // eslint-disable-next-line camelcase
      } else if (entity_type === "message") {
        // eslint-disable-next-line camelcase
        history.push(`/message?id=${entity_id}`);
      } else {
        return Promise.resolve();
      }
    } catch (error) {
      console.log(error);
    }
    return Promise.resolve();
  };

  const checkIfScrolledToBottom = (container) => {
    let subtractingResult =
      Math.round(container?.scrollHeight) - Math.round(container?.scrollTop);
    subtractingResult = Math.round(subtractingResult);

    return (
      subtractingResult - Math.round(container?.clientHeight) === 0 ||
      (subtractingResult - Math.round(container?.clientHeight) <= 1 &&
        subtractingResult - Math.round(container?.clientHeight) >= -1)
    );
  };
  const handleScroll = (e) => {
    if (e.target) {
      if (checkIfScrolledToBottom(e.target) && pagination.next_page_url) {
        getNotificationListing((pagination?.current_page || 0) + 1);
      }
    }
  };
  return (
    <Menu className="p0 h450">
      <div
        className="d-flex h-p99 flex1 o-y-auto nb-nice-scroll-style mxh-vh50 "
        onScroll={handleScroll}
      >
        {notificationListing?.data
          ?.filter(
            (obj, index, self) =>
              index ===
              self.findIndex((o) => o.notification_id === obj.notification_id)
          )
          .map((el) => {
            return (
              <>
                <Menu.Item
                  style={
                    el?.is_read
                      ? { backgroundColor: "#fff" }
                      : { backgroundColor: "#f9f9f9" }
                  }
                  className="py3 px6"
                  onClick={() => {
                    if (el.entity_type !== "default") {
                      NotificationDetails(el);
                    }
                  }}
                >
                  <div
                    className="display-flex"
                    style={{ alignItems: "center" }}
                  >
                    <Avatar
                      src={
                        el?.user?.profile_image ? el?.user?.profile_image : null
                      }
                      size="large"
                      style={{ backgroundColor: "#3155a6" }}
                    >
                      {getName(el?.user)}
                    </Avatar>
                    <div style={{ flex: 1 }}>
                      <a>
                        <div style={{ flex: 1, display: "flex", justifyContent: "space-between" }}>
                          <span
                            style={{ flex: 1 }}
                            className={`${
                              el.is_read ? "fnw-400" : "fnw-600"
                            } px2 truncate mb0  nb-base-text }`}
                          >
                            {el?.title}
                          </span>
                          <span className="nb-faded-text fn7">
                            {formattedFromNow(el.created_at)}
                          </span>
                        </div>
                        <p className="px2 truncate mb0 nb-faded-text">
                          {el?.body}
                        </p>
                      </a>
                    </div>
                  </div>
                </Menu.Item>
                <NbDivider />
              </>
            );
          })}
        {!isNotification && (
          <div className="px38  ">
            <p className="fnw600 py10">No Notification</p>
          </div>
        )}

        {notificationLoading && (
          <NotificationLoading loading={notificationLoading} />
        )}
      </div>
    </Menu>
  );
}

export default NotificationListing;
