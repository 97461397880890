import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Spin, Empty } from "antd";
import { useParams } from "react-router-dom";
import {
  SimpleCard,
  CardHeader,
} from "../../../../assets/gobal/components/Cards";
import { PeopleIcon } from "../../../../assets/icons/createPost";
import NeighboursList from "./neighboursList";
import { isArray } from "../../../../utils/functions";

function Wrapper({ children }) {
  return (
    <div className="display-flex justify-content-space-center align-items-center h-75">
      {children}
    </div>
  );
}

export default function ChooseNeighbours(props) {
  const {
    setShowChooseNeighbours,
    handleUpdateSelectedNeighbours,
    selectedNeighbours,
    editMode,
  } = props;
  const me = useSelector((state) => state.me);
  const { categoryId } = useParams();

  const [selectedNeighbour, updatedSelectedNeighbour] = useState("");

  const [selectedNearbyNeighbour, updatedSelectedNearbyNeighbour] = useState();
  const [panelActive, setPanelActive] = useState([]);

  const { neighboursLoader } = useSelector((state) => state.post);
  const { communities } = useSelector((state) => state.me);

  useEffect(() => {
    if (editMode) {
      updatedSelectedNearbyNeighbour([selectedNeighbours]);
    } else if (categoryId === "all" && !editMode) {
      updatedSelectedNearbyNeighbour([me?.communities[0]?.neighbourhood_id]);
    } else {
      updatedSelectedNearbyNeighbour([+categoryId]);
    }
  }, [categoryId]);
  console.log(selectedNeighbour);

  useEffect(() => {
    if (isArray(selectedNeighbours)) {
      updatedSelectedNearbyNeighbour(selectedNeighbours);
      updatedSelectedNeighbour(0);
      setPanelActive(["1"]);
    } else {
      updatedSelectedNeighbour(selectedNeighbours);
    }
  }, [selectedNeighbours]);

  const renderNeighboursList = () => {
    if (neighboursLoader) {
      return (
        <Wrapper>
          <Spin />
        </Wrapper>
      );
    } else if (communities?.length > 0) {
      return (
        <NeighboursList
          selectedNeighbour={selectedNearbyNeighbour}
          selectedNearbyNeighbour={selectedNearbyNeighbour}
          updatedSelectedNeighbour={updatedSelectedNeighbour}
          updatedSelectedNearbyNeighbour={updatedSelectedNearbyNeighbour}
          panelActive={panelActive}
          setPanelActive={setPanelActive}
        />
      );
    } else {
      return (
        <Wrapper>
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description="Community Data Not Found"
          />
        </Wrapper>
      );
    }
  };

  return (
    <SimpleCard containerClass="journey-card-container choose-neighbours-container">
      <CardHeader
        title="Choose Communities"
        IconComp={PeopleIcon}
        onBackBtnClick={() => setShowChooseNeighbours(false)}
        btnDisabled={!selectedNearbyNeighbour?.length}
        onDoneClick={() =>
          handleUpdateSelectedNeighbours(selectedNearbyNeighbour)
        }
      />

      {renderNeighboursList()}
    </SimpleCard>
  );
}
