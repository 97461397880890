import React, { useState } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { Menu, notification, Modal } from "antd";
import uniqBy from "lodash/uniqBy";

// components

import PostHead from "../postHead/postHead";
import PostMediaContent from "../postMediaContent/postMediaContent";
import PostSubjectMessage from "../postSubjectMessage/postSubjectMessage";
import ReportPostModal from "../reportPostModal/reportPostModal";
import PostHidden from "../../postHidden/postHidden";
import PostBottomSection from "../postBottomSection/postBottomSection";
// constants

// apis
import postApis from "../../../api/services/post/post";

// scss
import "./messagePost.scss";

// formatters
import {
  formattedFromNow,
  getPostHeadInfoUserName,
  useProfileSwitchingGuard,
} from "../../../utils";
import MetaTagsContent from "../metaTagsContent/metaTagsContent";

function MessagePost({
  data,
  showMore,
  showSharedCriteria,
  showSave,
  handleEditPost,
  hideImg,
}) {
  const {
    title,
    content,
    user,
    comment_count: commentCount,
    created_at: createdAt,
    is_public: isPublic,
    post_media: postMedia,
    meta_tags: metaTags,
    is_liked: isLiked,
    likes_count: likesCount,
    post_id: postId,
    comments: postComments,
    is_comment_disabled: isCommentDisabled,
    is_saved: isSaved,
    owner, // * for posts created by organisations, publications, businesses,
    post_owner: postOwner,
    edit_history_count: editHistoryCount,
    unique_reacts: uniqueReacts,
  } = data || {};

  const isCommercial = postOwner === "owner"; // * to check if the post is created by organisation or user

  const [isPostDeleted, setPostDeleted] = useState(false);
  const [isPostHidden, setPostHidden] = useState(false);
  const [isShowReportModal, setShowReportModal] = useState(false);
  const [isReportPostLoading, setReportPostLoading] = useState(false);
  const [isCommentDisabledLocal, setCommentDisabledLocal] =
    useState(isCommentDisabled);

  // report commnet
  const [isShowReportCommentModal, setShowReportCommentModal] = useState(false);
  const [commentIdRported, setCommentIdRported] = useState(null);
  const [isReportCommentLoading, setReportCommentLoading] = useState(false);

  const [isShowComments, setShowComments] = useState(false);
  const [moreComments, setMoreComments] = useState(postComments || []);
  const [moreCommentsPagination, setMoreCommentsPagination] = useState(null);
  const [moreRepliesPagination] = useState(null);
  const [isCommentFormLoading, setCommentFormLoading] = useState(false);
  const [commentFormResponse, setCommentFormResponse] = useState(false);
  const [likesCountState] = useState(likesCount);
  // reply
  const [_isReplyFormLoading, setReplyFormLoading] = useState(false);
  const me = useSelector((state) => state.me || {});
  const switchingGuard = useProfileSwitchingGuard();

  const authorId = isCommercial ? owner?.organisation_id : user?.user_id;
  const isAuthor = switchingGuard.isAuthor(authorId);

  const onClickComment = () => {
    setShowComments(!isShowComments);
  };

  const onComment = async ({ text, image, mediaId }) => {
    // re-check add media id to upload image in comment
    setCommentFormLoading(true);
    const comments = [...moreComments];
    try {
      const res = await postApis.commentPost(postId, {
        comment: text,
        media: mediaId,
        ...(image && { image_url: image }),
      });
      res.data.user = me;
      setMoreComments([...comments, res.data]);
      setCommentFormLoading(false);
      setCommentFormResponse(res);
    } catch (error) {
      setCommentFormLoading(false);
      setCommentFormResponse(error);

      notification.error({ message: error.message, duration: 5 });
    }
  };
  // enhanced for delete single comment only
  const onDeleteComment = async (commentId, replyId) => {
    try {
      if (commentId) {
        // if (commentId === replyId) {
        // means, this comment being deleted
        await postApis.deleteComment(postId, commentId);
        const comments = [...moreComments];
        const index = comments.findIndex(
          (comment) => comment.comment_id === commentId
        );
        if (index !== -1) {
          comments.splice(index, 1);
          setMoreComments(comments);
        }
      } else {
        // this is reply being deleted
        await postApis.deleteComment(postId, commentId);
        const comments = [...moreComments];
        const index = comments.findIndex(
          (comment) => comment.comment_id === commentId
        );
        const targetComment = comments[index];
        const deletedChildIndex = targetComment?.children?.findIndex(
          (child) => child.comment_id === replyId
        );
        if (deletedChildIndex !== -1) {
          targetComment.children.splice(deletedChildIndex, 1);
          setMoreComments(comments);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
  // added for delete single reply
  const onDeleteReply = async (ParentId, commentId) => {
    try {
      await postApis.deleteComment(postId, commentId);
      const comments = [...moreComments];
      const index = comments.findIndex(
        (comment) => comment.comment_id === ParentId
      );
      const targetComment = comments[index];
      const deletedChildIndex = targetComment?.children?.findIndex(
        (child) => child.comment_id === commentId
      );
      if (deletedChildIndex !== -1) {
        targetComment.children.splice(deletedChildIndex, 1);
        setMoreComments(comments);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const onDeleteComments = (commentId, replyId) => {
    Modal.confirm({
      icon: null,
      title:
        "Are you sure? Deleting this message permanently removes it from the book ecosystem website.",
      onOk() {
        return onDeleteComment(commentId, replyId);
      },
    });
  };
  // added for delete single reply
  const onDeleteReplys = (ParentId, commentId) => {
    Modal.confirm({
      icon: null,
      title:
        "Are you sure? Deleting this reply message permanently removes it from the book ecosystem website.",
      onOk() {
        return onDeleteReply(ParentId, commentId);
      },
    });
  };

  const onReact = async (reactionKey) => {
    try {
      await postApis.likePost(postId, {
        react_type: reactionKey,
      });
    } catch (error) {
      console.log(error);
    }
  };

  const onUnReact = async () => {
    try {
      await postApis.unlikePost(postId);
    } catch (error) {
      console.log(error);
    }
  };

  const onReactComment = async (reactionKey, commentId) => {
    try {
      await postApis.likeComment(postId, commentId, {
        react_type: reactionKey,
      });
    } catch (error) {
      console.log(error);
    }
  };

  const onUnReactComment = async (reactionKey, commentId) => {
    try {
      await postApis.unLikeComment(postId, commentId, {
        react_type: reactionKey,
      });
    } catch (error) {
      console.log(error);
    }
  };

  // re-check reply
  const onReplyComment = async ({ text, image, mediaId }, commentId) => {
    const comments = [...moreComments];
    const index = comments.findIndex((value) => value.comment_id === commentId);
    const targetComment = comments[index];
    targetComment.isReplyFormLoading = true;
    comments[index] = targetComment;
    setMoreComments(comments);

    try {
      setReplyFormLoading(true);
      const res = await postApis.replyComment(postId, {
        comment: text,
        media: mediaId,
        ...(image && { image_url: image }),
        comment_id: commentId,
      });
      res.data.user = me;
      targetComment.children = [
        ...(targetComment?.children
          ? [...targetComment.children, res.data]
          : [res.data]),
      ];
      targetComment.isReplyFormLoading = false;
      targetComment.apiResponse = { statusCode: res.statusCode };
      comments[index] = targetComment;
      setMoreComments(comments);
      setReplyFormLoading(false);
    } catch (error) {
      targetComment.isReplyFormLoading = false;
      targetComment.apiResponse = { statusCode: error.statusCode };
      comments[index] = targetComment;
      setMoreComments(comments);
      setReplyFormLoading(false);
    }
  };

  const onViewMoreComments = async () => {
    try {
      const currentPage = moreCommentsPagination
        ? moreCommentsPagination.current_page + 1
        : 1;

      const res = await postApis.getPostComments(postId, currentPage);
      const { data: comments, ...resOfData } = res.data;

      setMoreCommentsPagination(resOfData);
      const firstExistingComment =
        moreComments[0]?.comment_id === comments[0]?.comment_id;
      if (firstExistingComment || currentPage === 1) {
        setMoreComments([...comments]);
      } else {
        setMoreComments([...moreComments, ...comments]);
      }
    } catch (error) {
      console.log(error);
    }
    return undefined;
  };

  const onViewMoreReply = async (commentId) => {
    const comments = [...moreComments];
    const index = comments.findIndex((value) => value.comment_id === commentId);
    const targetComment = comments[index];
    const currentPage = moreRepliesPagination
      ? moreRepliesPagination.current_page + 1
      : 1;
    try {
      const res = await postApis.getCommentsReply(
        postId,
        commentId,
        currentPage
      );
      const { data: replies, ...resOfData } = res.data;
      const filteredComments = uniqBy(
        [...targetComment.children, ...replies],
        "comment_id"
      );
      targetComment.children = filteredComments;
      targetComment.pagination = resOfData;
      comments[index] = targetComment;
      setMoreComments(comments);
    } catch (error) {
      console.log(error);
    }
  };

  const onDeletePost = (id) => {
    Modal.confirm({
      icon: null,
      title:
        "Are you sure? Deleting this message permanently removes it from the book ecosystem website.",
      onOk() {
        return new Promise((resolve) => {
          postApis.deletePost(id).then(() => {
            resolve();
            setPostDeleted(true);
          });
        });
      },
    });
  };

  const onShowReportModal = () => {
    setShowReportModal(true);
  };

  const onHideReportModal = () => {
    setShowReportModal(false);
  };

  const onReportPost = async (id, reportCase) => {
    try {
      setReportPostLoading(true);
      await postApis.reportPost(id, {
        body: reportCase,
        flag: [reportCase],
      });
      setReportPostLoading(false);
      onHideReportModal();
      notification.open({
        message: "",
        description:
          "Thanks for reporting this message. This will be reviewed against the book ecosystem Guidelines shortly",
        className: "nb-post-notification",
      });
    } catch (error) {
      setReportPostLoading(false);
      console.log(error);
    }
  };

  const onHidePost = async (id) => {
    try {
      await postApis.onHidePost(id);
      setPostHidden(true);
    } catch (error) {
      console.log(error);
    }
  };

  const onUnHidePost = async (id) => {
    try {
      await postApis.onUnHidePost(id);
      setPostHidden(false);
    } catch (error) {
      console.log(error);
    }
  };

  const onSavePost = async (id) => {
    try {
      await postApis.onSavePost(id);
    } catch (error) {
      console.log(error);
    }
  };

  const onUnSavePost = async (id) => {
    try {
      await postApis.onUnSavePost(id);
    } catch (error) {
      console.log(error);
    }
  };

  const onDisableReplies = async (id) => {
    try {
      await postApis.disableReplies(id);
      setCommentDisabledLocal(true);
    } catch (error) {
      console.log(error);
    }
  };

  const onEnableReplies = async (id) => {
    try {
      await postApis.enableReplies(id);
      setCommentDisabledLocal(false);
    } catch (error) {
      console.log(error);
    }
  };

  const onShowReportCommentModal = (commentId, replyId) => {
    // check if the comment being reported is comment or a reply
    const commentIdBeingReported = commentId === replyId ? commentId : replyId;
    setCommentIdRported(commentIdBeingReported);
    setShowReportCommentModal(true);
  };

  const onHideReportCommentModal = () => {
    setShowReportCommentModal(false);
  };

  const onReportComment = async (id, commentId, reportCase) => {
    try {
      setReportCommentLoading(true);
      await postApis.reportComment(id, commentId, {
        body: reportCase,
      });
      setReportCommentLoading(false);
      onHideReportCommentModal();
      notification.open({
        message: "",
        description:
          "Thanks for reporting this comment. This will be reviewed against the book ecosystem Guidelines shortly",
        className: "nb-post-notification",
      });
    } catch (error) {
      setReportCommentLoading(false);
      console.log(error);
    }
  };

  const isShowViewMore = (pagination) => {
    if (pagination == null) {
      return true;
    } else if (pagination.next_page_url === null) {
      return false;
    } else {
      return true;
    }
  };

  const menu = isAuthor ? (
    <Menu>
      <Menu.Item>
        <button
          type="button"
          className="nb-proxy-button w-p100"
          onClick={() => handleEditPost?.(data)}
        >
          Edit
        </button>
      </Menu.Item>
      <Menu.Item>
        <button
          onClick={() => onDeletePost(postId)}
          type="button"
          className="nb-proxy-button w-p100"
        >
          Delete
        </button>
      </Menu.Item>
      <Menu.Item>
        <button
          onClick={() => {
            if (isCommentDisabledLocal) {
              onEnableReplies(postId);
            } else {
              onDisableReplies(postId);
            }
          }}
          type="button"
          className="nb-proxy-button w-p100"
        >
          {isCommentDisabledLocal ? "Enable Replies" : "Disable Replies"}
        </button>
      </Menu.Item>
    </Menu>
  ) : (
    <Menu>
      <Menu.Item>
        <button
          onClick={() => onHidePost(postId)}
          type="button"
          className="nb-proxy-button w-p100"
        >
          Hide
        </button>
      </Menu.Item>
      <Menu.Item>
        <button
          onClick={onShowReportModal}
          type="button"
          className="nb-proxy-button w-p100"
        >
          Report
        </button>
      </Menu.Item>
    </Menu>
  );

  // return is the post is deleted and to remove it locally
  if (isPostDeleted) {
    return null;
  }
  // return is the post is hidden and to hide it locally
  if (isPostHidden) {
    return <PostHidden onUnHide={() => onUnHidePost(postId)} />;
  }
  return (
    <>
      <div className={hideImg ? "" : "message-post py4 mb4"}>
        <PostHead
          className="px4"
          userId={data?.user?.user_id}
          data={data}
          name={getPostHeadInfoUserName(data)}
          imageUrl={user?.profile_image ?? owner?.logo?.url}
          neighbourhood={user?.neighbourhood ?? owner?.neighbourhood}
          createdAt={formattedFromNow(createdAt)}
          isPublic={!!isPublic}
          actionMenu={showMore ? menu : null}
          isEdited={editHistoryCount > 1}
        />
        <PostSubjectMessage className="px4" subject={title} message={content} />

        {postMedia?.length !== 0 && !hideImg && (
          <PostMediaContent media={postMedia} />
        )}

        {metaTags?.length !== 0 && !hideImg && (
          <MetaTagsContent metaTags={metaTags} />
        )}

        <PostBottomSection
          // * post actions
          isLiked={isLiked}
          likesCount={likesCountState}
          onClickComment={onClickComment}
          onReact={onReact}
          onUnReact={onUnReact}
          isSaved={isSaved}
          onSavePost={() => onSavePost(postId)}
          onUnSavePost={() => onUnSavePost(postId)}
          showSave={showSave}
          // * PostCommentForm
          isShowComments={isShowComments}
          isCommentDisabledLocal={isCommentDisabledLocal}
          onComment={onComment}
          isLoading={isCommentFormLoading}
          apiResponse={commentFormResponse}
          // * PostComments
          onReplyComment={onReplyComment}
          onReactComment={onReactComment}
          onUnReactComment={onUnReactComment}
          onDeleteComment={onDeleteComments}
          // added for delete single reply
          onDeleteReply={onDeleteReplys}
          onReportComment={onShowReportCommentModal}
          comments={moreComments}
          onViewMore={onViewMoreComments}
          isShowViewMore={
            commentCount > 1 &&
            isShowViewMore(moreCommentsPagination) &&
            moreComments.length !== 0
          }
          onViewMoreReply={onViewMoreReply}
          isShowViewMoreReplies
          me={me}
          commentsDisabled={isCommentDisabledLocal}
          uniqueReacts={uniqueReacts}
        />
      </div>

      <ReportPostModal
        visible={isShowReportModal}
        onCancel={onHideReportModal}
        onOk={(reportCase) => onReportPost(postId, reportCase)}
        loading={isReportPostLoading}
        destroyOnClose
        hideOptions={isCommercial ? ["Commercial"] : []}
      />
      <ReportPostModal
        visible={isShowReportCommentModal}
        onCancel={onHideReportCommentModal}
        onOk={(reportCase) =>
          onReportComment(postId, commentIdRported, reportCase)
        }
        loading={isReportCommentLoading}
        destroyOnClose
      />
    </>
  );
}

export default MessagePost;

MessagePost.defaultProps = {
  showMore: true,
  showSharedCriteria: true,
  showSave: true,
};

MessagePost.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  showMore: PropTypes.bool,
  showSharedCriteria: PropTypes.bool,
  showSave: PropTypes.bool,
};
