import React, { Suspense } from "react";
import { Popover } from "antd";
import PropTypes from "prop-types";

// icons
import Icons from "../../../icons/createPost";

// scss
import "./emojiMart.scss";
// import "emoji-mart/css/emoji-mart.css";

const EmojiPicker = React.lazy(() => import("./emojiMartForImport"));

export default function EmojiMart({ onClick, overlayClassName }) {
  return (
    <Popover
      placement="rightBottom"
      content={
        <Suspense fallback={<div />}>
          <EmojiPicker onClick={(e) => onClick?.(e)} />
        </Suspense>
      }
      trigger="click"
      overlayClassName={`emoji-mart-wrap ${overlayClassName}`}
      overlayInnerStyle={{ padding: 0 }}
    >
      <img
        className="emoji-mart-trigger-icon c-pointer ml3"
        src={Icons.Smiley}
        alt="Smily"
      />
    </Popover>
  );
}

EmojiMart.defaultProps = {
  onClick: () => null,
  overlayClassName: "",
};

EmojiMart.propTypes = {
  onClick: PropTypes.func,
  overlayClassName: PropTypes.string,
};
