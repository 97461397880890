import React from "react";
import PropTypes from "prop-types";

// scss
import "./metaTagsContent.scss";

function MetaTagsContent({ metaTags, className }) {
  return (
    metaTags &&
    [...metaTags]?.map((metaTag) => (
      <a
        key={metaTag?.link}
        href={metaTag?.link}
        target="_blank"
        rel="noreferrer"
        className={`post-url-container px4 ${className}`}
      >
        <img
          className="post-url-image"
          src={metaTag?.image}
          alt={metaTag?.description}
        />
        <div className="post-url-info">
          <img
            className="post-url-image-responsive"
            src={metaTag?.image}
            alt={metaTag?.description}
          />
          <h6>{metaTag?.title}</h6>
          <p>{metaTag?.description}</p>
          <span className="post-url-link-info">{metaTag?.link}</span>
        </div>
      </a>
    ))
  );
}

export default MetaTagsContent;

MetaTagsContent.defaultProps = {
  metaTags: undefined,
  className: "",
};

MetaTagsContent.propTypes = {
  metaTags: PropTypes.arrayOf(PropTypes.object),
  className: PropTypes.string,
};
