import { formatPrice } from "./numberFormatters";

export const getLengthBasedSingularLable = (label, length) => {
  return length <= 1 ? label : `${label}s`;
};

export const getRsvpButtonText = (rsvpStatus) => {
  if (rsvpStatus === "yes") {
    return "Attending";
  } else if (rsvpStatus === "no") {
    return "Not Interested";
  } else if (rsvpStatus === "maybe") {
    return "Might be attending";
  } else {
    return "Add R.S.V.P";
  }
};

export const getMarketplaceItemStatus = (categorySlug, price) => {
  if (
    (categorySlug === "sell" || categorySlug === "buy") &&
    !price &&
    !+price
  ) {
    return "Negotiable";
  } else if (categorySlug === "free-stuff") {
    return "Free";
  }
  return formatPrice(price);
};

export const getRootRoutingLink = (isAuthenticated) => {
  return isAuthenticated ? "/home/all" : "/";
};

// formatBusiness categories
export const getFormattedBusinessCategories = (categoriesLocal) => {
  let categoryText = "Other";
  if (!categoriesLocal.length) return categoryText;
  else if (categoriesLocal.length && categoriesLocal.length > 1) {
    categoryText = `${categoriesLocal[0].name} + ${
      categoriesLocal.length - 1
    } Others`;
  } else {
    categoryText = categoriesLocal[0].name;
  }
  return categoryText;
};
export const getInitialChars = (char) => {
  if (!char || typeof char !== "string") {
    return char;
  }
  let splitedChar = char.split(" ");
  splitedChar = `${splitedChar.shift()?.charAt(0).toLocaleUpperCase() ?? ""}${
    splitedChar.pop()?.charAt(0).toLocaleUpperCase() ?? ""
  }`;
  return splitedChar;
};

// takes the post object as input and returns user first and last name or organistion or business/ organisation/punlication name
export const getPostHeadInfoUserName = (data) => {
  return `${
    data[data?.post_owner]?.name ?? data[data?.post_owner]?.first_name
  } ${data[data?.post_owner]?.last_name ?? ""}`;
};
