export const getExtentionFromUrl = (url) => {
  return url?.split(".").pop().toLocaleLowerCase();
};

export const formatMeida = (media) => {
  const images = [];
  const pdf = [];
  media?.forEach((element) => {
    const { media: mediaItem } = element;
    const extention = getExtentionFromUrl(mediaItem?.url);

    if (mediaItem?.type === "pdf" || extention === "pdf") {
      pdf.push(mediaItem);
    } else {
      images.push({
        ...mediaItem,
        original: mediaItem.url,
        thumbnail: mediaItem.url,
        originalHeight: "450px",
        bulletClass: "custom-bullets",
        originalClass: "cursor-pointer",
      });
    }
  });
  return {
    images,
    pdf,
  };
};
