import React from "react";
import { Layout } from "antd";
import "./listingLayout.scss";

const { Sider, Content } = Layout;

function ListingLayout({
  children,
  leftSider,
  rightSider,
  header,
  className,
  contentClassName,
}) {
  return (
    <Layout className={`nb-listing-layout ${className ?? ""}`}>
      {leftSider?.component && (
        <Sider
          className="nb-listing-layout-body-left"
          width="320px"
          breakpoint="xxl"
          collapsedWidth="0"
        >
          {leftSider?.component}
        </Sider>
      )}

      <Layout className="nb-listing-layout-body">
        {header}
        {children && (
          <div className="nb-listing-layout-main-content p0">
            <div className="global-shadow-background-page nb-full-screen display-flex justify-content-center">
              <Content
                className={`${leftSider ? "" : "pl0"} ${contentClassName ?? ""}`}
              >
                {children}
              </Content>
            </div>
          </div>
        )}
      </Layout>
    </Layout>
  );
}

export default ListingLayout;
