import React, { useState, useEffect, memo } from "react";
import { Dropdown, Badge, Menu } from "antd";
import { useSelector } from "react-redux";
import { ReactComponent as BellIcon } from "../../../icons/notification.svg";
import NotificationsService from "../../../../api/services/notifications/notification";
import { getEchoInstance } from "../../../../utils";
import NotificationListing from "./notificationListing";
import "./header.scss";

function Notification() {
  const me = useSelector((state) => state.me);
  const [unreadCount, setUnReadCount] = useState(0);
  const NotificationCount = async () => {
    try {
      const res = await NotificationsService.getNotificationListing();
      setUnReadCount(res.data.unread_count);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    NotificationCount();
  }, []);
  const handleNotificationCount = (data) => {
    NotificationCount();
  };
  const echo = getEchoInstance();
  useEffect(() => {
    echo
      ?.private(`notification-${me?.user_id}`)
      ?.listen(".new-notification", handleNotificationCount);
  }, []);

  return (
    <Dropdown
      overlayClassName="notification-container"
      overlay={
        <NotificationListing
          setUnReadCount={setUnReadCount}
          unreadCount={unreadCount}
        />
      }
      // menu={{
      //   items: [
      //     {
      //       key: "1",
      //       label: (
      //         <NotificationListing
      //           setUnReadCount={setUnReadCount}
      //           unreadCount={unreadCount}
      //         />
      //       ),
      //     },
      //   ],
      // }}
      trigger={["click"]}
      placement="bottomRight"
    >
      <a
        href="#"
        onClick={(e) => e.preventDefault()}
        className="notification-dropdown"
      >
        <Badge offset={[-28, 0]} count={unreadCount}>
          <div key="notification" className="">
            <BellIcon />
          </div>
        </Badge>
        {/* <div className="mt1 notification-label">Notification</div> */}
      </a>
    </Dropdown>
  );
}

export default memo(Notification);
