import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal } from "antd";
import LoginForm from "../loginForm/loginForm";
import authApis from "../../api/services/auth/auth";
import tokenActions from "../../store/auth/action";
import globalComponentsActions from "../../store/Actions/globalComponentsAction";

export default function LoginModal() {
  const [loginResponse, setLoginResponse] = useState("Message");
  const [loginLoading, setLoginLoading] = useState(false);
  const { loginModal } = useSelector((state) => state.globalComponents);
  const dispatch = useDispatch();

  /* istanbul ignore next */
  const loginSubmit = async (data) => {
    try {
      setLoginLoading(true);
      const res = await authApis.userLogin({
        ...data,
        password: window.btoa(window.btoa(data.password)),
      });
      setLoginLoading(false);
      const { token } = res.data;
      window.localStorage.setItem("signupAuthToken", token);
      dispatch(tokenActions.Token(token));
      dispatch(globalComponentsActions.showHideLoginModal(false));
    } catch (err) {
      setLoginResponse(err);
      setLoginLoading(false);
    }
  };
  return (
    <Modal
      open={loginModal?.visible}
      onCancel={() =>
        dispatch(globalComponentsActions.showHideLoginModal(false))
      }
      footer={null}
      centered
      destroyOnClose
    >
      <LoginForm
        onFinish={(data) => loginSubmit(data)}
        apiResponse={loginResponse}
        loading={loginLoading}
      />
    </Modal>
  );
}
