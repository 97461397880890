/* eslint-disable default-param-last */
const initialState = {
  Token: null,
};

export default function reducer(state = initialState, action) {
  if (action.type === "TOKEN") {
    return { ...state, Token: action.payload };
  } else {
    return state;
  }
}
