const debounce = (...params) => {
  const [func, delay] = params;
  let inDebounce;
  return function x() {
    const context = this;
    const args = params;
    clearTimeout(inDebounce);
    inDebounce = setTimeout(() => func.apply(context, args), delay);
  };
};

const throttle = (...params) => {
  const [func, limit] = params;

  let inThrottle;
  return function x() {
    const args = params;
    const context = this;
    if (!inThrottle) {
      func.apply(context, args);
      inThrottle = true;
      setTimeout(() => {
        inThrottle = false;
      }, limit);
    }
  };
};

export const isArray = (data) => Array.isArray(data);

const functions = {
  debounce,
  throttle,
};

export default functions;
