import React from "react";
import PropTypes from "prop-types";
import ReactImageGallery from "react-image-gallery";
import DocumentContent from "./documentContent/documentContent";

import { formatMeida } from "../../../utils";

// scss
import "./postMediaContent.scss";

function PostMediaContent({ media, className }) {
  const { images, pdf } = formatMeida(media);
  return (
    <div className={`post-media-content mb2 ${className}`}>
      <DocumentContent pdf={pdf} />
      <ReactImageGallery
        items={images}
        showFullscreenButton={false}
        showPlayButton={false}
        onClick={(e) => {
          const a = document.createElement("a");
          a.href = e.target.currentSrc;
          a.target = "_blank";
          a.click();
        }}
        showBullets={images.length > 1}
        showThumbnails={images.length > 1}
        disableKeyDown
      />
    </div>
  );
}

export default PostMediaContent;

PostMediaContent.defaultProps = {
  media: undefined,
  className: "",
};

PostMediaContent.propTypes = {
  media: PropTypes.arrayOf(PropTypes.any),
  className: PropTypes.string,
};
