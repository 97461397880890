import React, { Suspense, useState, useEffect } from "react";
import { Route, Switch, useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

// components
import FallbackLoader from "./assets/gobal/components/fallbackLoader/fallbackLoader";

// apis
import authApis from "./api/services/auth/auth";

// actions
import tokenActions from "./store/auth/action";
import { getNeighbours } from "./store/Actions/postActions";

// constants
import { PRIVATE_ROUTES, PUBLIC_ROUTES } from "./constants";

/* istanbul ignore next */
const PageNotFound = React.lazy(() =>
  import("./assets/gobal/components/pageNotFound/pageNotFound")
);

function Routes() {
  const dispatch = useDispatch();
  const history = useHistory();
  const token = useSelector((state) => state.auth.Token);
  const me = useSelector((state) => state.me);
  const [isLoading, setLoading] = useState(false);
  const [isAuthenticated, setAuthenticated] = useState(false);

  useEffect(() => {
    if (me && !me?.user_id) {
      dispatch(tokenActions.Token(null));
      dispatch(tokenActions.clearMe());
      window.localStorage.removeItem("signupAuthToken");
      history.push("/");
    }
  }, [me]);

  /* istanbul ignore next */
  useEffect(() => {
    (async () => {
      try {
        if (token) {
          setLoading(true);
          // if there is a token, then head over network to verify it, and get user
          const { data } = await authApis.getMe(token);
          dispatch(tokenActions.saveMe(data));
          dispatch(getNeighbours());
          setAuthenticated(true);
          setLoading(false);
        } else {
          throw new Error();
        }
      } catch (err) {
        setLoading(false);
        setAuthenticated(false);
      }
    })();
  }, [token]);

  const pageNotFound = () => {
    return <Route component={PageNotFound} />;
  };

  const publicRoutes = () => {
    return (
      !isAuthenticated && (
        <Switch>
          {PUBLIC_ROUTES.map((route) => (
            <Route
              key={route.id}
              path={route.path}
              exact={route.exact}
              component={route.component}
            />
          ))}
          {pageNotFound()}
        </Switch>
      )
    );
  };

  const privateRoute = () => {
    /* istanbul ignore next */
    return (
      isAuthenticated && (
        <Switch>
          {PRIVATE_ROUTES.map((route) => (
            // eslint-disable-next-line react/jsx-indent
            <Route
              key={route.id}
              path={route.path}
              exact={route.exact}
              component={route.component}
            />
          ))}
          {pageNotFound()}
        </Switch>
      )
    );
  };

  /* istanbul ignore next */
  if (isLoading) {
    return <FallbackLoader />;
  }

  return (
    <Suspense fallback={<FallbackLoader />}>
      {publicRoutes()}
      {privateRoute()}
    </Suspense>
  );
}
export default Routes;
