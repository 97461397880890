import { isArray } from "../../../utils/functions";

export const getSelectedNeighoursData = (data, selectedNeighbours, meData) => {
  // re-check
  const updatedMessageJourneyData = { ...data };
  updatedMessageJourneyData.selectedNeighbours = selectedNeighbours;
  if (isArray(selectedNeighbours)) {
    const selectedNeighboursData = meData.communities.filter(
      (nearbyNeighbour) =>
        selectedNeighbours.find(
          (item) => nearbyNeighbour.neighbourhood_id === item
        )
    );

    const selectedNeighboursDataName = selectedNeighboursData.map(
      (dataName) => dataName.name
    );

    updatedMessageJourneyData.selectedNeighboursTitle = `${
      selectedNeighboursData.length ? selectedNeighboursDataName.toString() : ""
    }`;
  } else if (meData.neighbourhood_id === selectedNeighbours) {
    updatedMessageJourneyData.selectedNeighboursTitle = meData.name;
  }
  return updatedMessageJourneyData;
};

export const getSelectedCategoryData = (data, selectedCat, childCategories) => {
  const updatedMessageJourneyData = { ...data };
  updatedMessageJourneyData.selectedCategory = selectedCat;
  const selectedCategory = childCategories.find(
    (category) => category.category_id === selectedCat.category_id
  );
  updatedMessageJourneyData.selectedCategoryTitle = selectedCategory.name;
  return updatedMessageJourneyData;
};

export const getSelectedNeighoursIds = (neighbourhoods) => {
  if (!neighbourhoods?.length) return "";
  else if (neighbourhoods?.length === 1) {
    return neighbourhoods[0]?.neighbourhood_id;
  }
  return neighbourhoods.map((neighbourhood) => neighbourhood?.neighbourhood_id);
};

export const getSelectedNeighoursTitle = (neighbourhoods) => {
  if (!neighbourhoods?.length) return "";

  let selectedNeighboursTitle = "";

  selectedNeighboursTitle = neighbourhoods[0]?.name;

  if (neighbourhoods?.length > 1) {
    const selectedValue = neighbourhoods?.map((data) => data.name);
    selectedNeighboursTitle = selectedValue?.join(",");
  }

  return selectedNeighboursTitle;
};
