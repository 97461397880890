import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

// icons
import { World } from "../../../icons/post";
import NbAvatar from "../nbAvatar/nbAvatar";

function NbUserCardInfo({
  style,
  className,
  name,
  imageUrl,
  neighborhood,
  isPublic,
  alternateInfo,
  data,
}) {
  return (
    <div
      className={`user-head-info display-flex ${className ?? ""}`}
      style={style}
    >
      <div className="mr4">
        <NbAvatar
          profileImage={imageUrl}
          firstName={data?.first_name}
          lastName={data?.last_name}
        />
      </div>
      <div className="display-flex align-items-center flex1">
        <div className="flex1">
          <p className="display-flex align-items-center m0 nb-base-text">
            {name}
          </p>
          {!alternateInfo ? (
            <p className="fn7 mb0">
              {neighborhood && <span className="nb-faded-text mr1">from</span>}
              <Link
                to={`/other-neighborhoods/${neighborhood?.neighborhood_id}`}
              >
                {neighborhood?.name}
              </Link>
              {isPublic && (
                <>
                  <World className="ml4 user-head-privacy-icon" />
                  <span className="ml1">Public</span>
                </>
              )}
            </p>
          ) : (
            <p className="fn7 mb0 nb-base-text">{alternateInfo}</p>
          )}
        </div>
      </div>
    </div>
  );
}

export default NbUserCardInfo;

NbUserCardInfo.defaultProps = {
  style: {},
  className: "",
  neighborhood: undefined,
  isPublic: false,
  alternateInfo: undefined,
};

NbUserCardInfo.propTypes = {
  style: PropTypes.objectOf(PropTypes.object),
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
  imageUrl: PropTypes.string.isRequired,
  neighborhood: PropTypes.instanceOf(PropTypes.object),
  isPublic: PropTypes.bool,
  alternateInfo: PropTypes.element,
};
