import React from "react";
import { Menu } from "antd";
import NotificationSkeleton from "../nbSkeleton/notificationSkeleton/notificationSkeleton";

function NotificationLoading({ loading }) {
  return (
    <Menu>
      <NotificationSkeleton loading={loading} />
    </Menu>
  );
}

export default NotificationLoading;
