import {
  GET_NEIGHBOURS,
  GET_NEIGHBOURS_SUCCESS,
  GET_NEIGHBOURS_FAILURE,
  GET_CATEGORIES,
  GET_CATEGORIES_SUCCESS,
  GET_CATEGORIES_FAILURE,
  GET_CHILD_CATEGORIES,
  GET_CHILD_CATEGORIES_SUCCESS,
  GET_CHILD_CATEGORIES_FAILURE,
} from "../constants";

import postApis from "../../api/services/post/post";
import { store } from "../createStore";

// GET NEIGHBOURS
export function getNeighbours(payload) {
  return async (dispatch) => {
    dispatch({
      type: GET_NEIGHBOURS,
      payload,
    });

    try {
      const res = await postApis.getNeighbours();
      if (res?.data) {
        dispatch({
          type: GET_NEIGHBOURS_SUCCESS,
          payload: res?.data,
        });
      } else {
        throw new Error(res.message);
      }
    } catch (error) {
      dispatch({
        type: GET_NEIGHBOURS_FAILURE,
        error,
      });
    }
  };
}

// GET CATEGORIES
export function getCategories(payload) {
  return async (dispatch) => {
    dispatch({
      type: GET_CATEGORIES,
      payload,
    });

    try {
      const res = await postApis.postCategory();
      if (res?.data) {
        dispatch({
          type: GET_CATEGORIES_SUCCESS,
          payload: res?.data,
        });
      } else {
        throw new Error(res.message);
      }
    } catch (error) {
      dispatch({
        type: GET_CATEGORIES_FAILURE,
        error,
      });
    }
  };
}

// GET CHILD CATEGORIES
export function getChildCategories(payload, cancelToken, pageNo) {
  return async (dispatch) => {
    dispatch({
      type: GET_CHILD_CATEGORIES,
      payload,
    });

    try {
      let res = null;
      if (payload.catId) {
        res = await postApis.getCategories(payload.catId, cancelToken);
      }

      if (res?.data?.data) {
        const { current_page: currentPage, next_page_url: nextPageUrl } =
          res?.data || {};

        const {
          post: { childCategories },
        } = store.getState();

        let newPayload = [...(res?.data?.data || [])];

        if (childCategories?.recommendation?.length) {
          newPayload = [
            ...newPayload,
            ...(childCategories?.recommendation || []),
          ];
        }

        dispatch({
          type: GET_CHILD_CATEGORIES_SUCCESS,
          payload: {
            [payload.slug]: newPayload,
            currentPage,
            nextPageUrl,
          },
        });
      } else if (res?.data) {
        dispatch({
          type: GET_CHILD_CATEGORIES_SUCCESS,
          payload: { [payload.slug]: res?.data },
        });
      } else {
        throw new Error(res.message);
      }
    } catch (error) {
      dispatch({
        type: GET_CHILD_CATEGORIES_FAILURE,
        error,
      });
    }
  };
}
