import React, { useState, useEffect } from "react";
import { UserOutlined, MessageOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { Tabs, Row, Col, Spin } from "antd";
import Container from "../../assets/gobal/components/container/container";
import ListingLayout from "../../assets/gobal/components/listingLayout/listingLayout";
import HomeSider from "../home/homeSider/homeSider";
import NbUserCardInfo from "../../assets/gobal/components/nbUserCardInfo/nbUserCardInfo";
import GlobalSearchAPI from "../../api/services/globalSearch/globalSearch";
import NbSimpleCard from "../../assets/gobal/components/nbSimpleCard/nbSimpleCard";
import NbDivider from "../../assets/gobal/components/nbDivider/nbDivider";
import Header from "../../assets/gobal/components/header/header";
import "./globalSearch.scss";

export default function GlobalSearch(props) {
  const [activeTab, setActiveTab] = useState("all");
  const [searchData, setSearchData] = useState(null);
  const [isLoader, setIsLoader] = useState(true);
  useEffect(() => {
    /* istanbul ignore next */
    (async () => {
      try {
        const res = await GlobalSearchAPI.globalSearching(
          props?.location?.state?.string || localStorage.getItem("searchString")
        );
        setSearchData(res);
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoader(false);
        setActiveTab("all");
      }
    })();
  }, [props?.location?.state?.string, localStorage.getItem("searchString")]);

  const neighbourRender = () => {
    /* istanbul ignore next */
    return (
      <NbSimpleCard>
        <div className="display-flex">
          <UserOutlined style={{ fontSize: "20px" }} className="mt1 mr2" />
          <h4 className="nb-primary-text m0">Community Members</h4>
        </div>
        {searchData?.data?.users?.data?.length ? (
          <NbDivider noStyle={false} className="apply-bottom-border-grey" />
        ) : null}
        {searchData?.data?.users?.data?.map((el, i) => {
          return (
            <>
              <Link to={`/profile/${el?.user_id}`}>
                <NbUserCardInfo
                  className="m4"
                  avatarSize={40}
                  name={
                    <p className="mb0">{`${el.first_name} ${el.last_name}`}</p>
                  }
                  imageUrl={el.profile_image}
                />
              </Link>
              {(searchData?.data?.users?.data?.length || 0) - 1 !== i && (
                <NbDivider
                  noStyle={false}
                  className="apply-bottom-border-grey"
                />
              )}
            </>
          );
        })}
      </NbSimpleCard>
    );
  };

  const postRender = () => {
    /* istanbul ignore next */
    return (
      <NbSimpleCard>
        <div className="display-flex  global-post-wrapper">
          <MessageOutlined style={{ fontSize: "20px" }} className="mt1 mr2" />
          <h4 className="nb-primary-text m0">Posts</h4>
        </div>
        {searchData?.data?.posts?.data?.length ? (
          <NbDivider noStyle={false} className="apply-bottom-border-grey" />
        ) : null}
        {searchData?.data?.posts?.data?.map((el, i) => {
          return (
            <Link
              key={el?.post_id}
              to={{
                pathname: `/post/view/${el?.post_id}`,
                query: { id: el?.post_id },
              }}
            >
              <div className="ml4 post-description">
                <p className="fnw-700 mb0 truncate ">{el?.title}</p>
                <p className="nb-faded-text truncate">{el?.content}</p>
              </div>
              {(searchData?.data?.posts?.data?.length || 0) - 1 !== i && (
                <NbDivider
                  noStyle={false}
                  className="apply-bottom-border-grey"
                />
              )}
            </Link>
          );
        })}
      </NbSimpleCard>
    );
  };

  return (
    <Container className="neighbour-container" fluid>
      <ListingLayout
        header={<Header />}
        className="home-listing-layout"
        leftSider={{
          width: "320px",
          component: <HomeSider />,
        }}
        contentClassName="business-layout-content w-100"
      >
        <div className="mx5">
          <h5 className="pt5">Search Results</h5>
          {isLoader ? (
            <div className="global-search-loader">
              <Spin />
            </div>
          ) : (
            <div className="nb-listing-layout-tabs global-search-wrapper">
              <Row gutter={48} className="justify-content-center">
                <Col className="ant-col-lg-20 ant-col-md-24">
                  <Tabs
                    activeKey={activeTab}
                    onChange={
                      /* istanbul ignore next */
                      (e) => setActiveTab(e)
                    }
                  >
                    <Tabs.TabPane
                      tab={`All (${searchData?.total})`}
                      key="all"
                      className="nb-listing-layout-tabs-pane"
                    >
                      {neighbourRender()}
                      {postRender()}
                    </Tabs.TabPane>
                    <Tabs.TabPane
                      tab={`Community Members (${searchData?.data?.users?.total})`}
                      key="neighbours"
                      className="nb-listing-layout-tabs-pane"
                    >
                      {neighbourRender()}
                    </Tabs.TabPane>
                    <Tabs.TabPane
                      tab={`Posts (${searchData?.data?.posts?.total})`}
                      key="post"
                      className="nb-listing-layout-tabs-pane"
                    >
                      {postRender()}
                    </Tabs.TabPane>
                  </Tabs>
                </Col>
              </Row>
            </div>
          )}
        </div>
      </ListingLayout>
    </Container>
  );
}
