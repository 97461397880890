import React from "react";
import SideIcon from "../../../assets/gobal/components/sideIcon";

export default function JourneyOptionBtn(props) {
  const {
    title,
    placeholder,
    containerClassName,
    titleClass,
    leftIcon,
    rightIcon,
    leftIconClass,
    rightIconClass,
    leftIconContainerClassName,
    rightIconContainerClassName,
    onClick,
    showRightIcon,
    disabled,
  } = props;

  return (
    <div
      role="toolbar"
      className={`journey-option-container ${containerClassName ?? ""} ${
        disabled ? "journey-option-container-disabled" : ""
      }`}
      onClick={onClick}
      onKeyDown={onClick}
    >
      <SideIcon
        Icon={leftIcon}
        containerClassName={leftIconContainerClassName}
        iconClassName={leftIconClass}
      />
      <p
        className={`journey-option-text ${
          title ? "journey-option-text-filled" : ""
        } ${titleClass ?? ""}`}
      >
        {title || placeholder}
      </p>
      {showRightIcon && (
        <SideIcon
          Icon={rightIcon}
          containerClassName={rightIconContainerClassName}
          iconClassName={rightIconClass}
        />
      )}
    </div>
  );
}
