import { Post, Delete } from "../../requests";

const upload = (params) => {
  const formDataBody = new FormData();
  Object.keys(params).forEach((key) => {
    const file = params[key];
    formDataBody.append(key, file);
  });
  return Post(`/upload`, formDataBody, {
    "content-type": "multipart/form-data",
  });
};

const deleteMedia = (mId) => {
  return Delete(`/delete-file/${mId}`);
};

const mediaApis = {
  upload,
  deleteMedia
};

export default mediaApis;
