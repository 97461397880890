/* eslint-disable default-param-last */
export default function reducer(state = null, action) {
  switch (action.type) {
    case "ME":
      return { ...state, ...action.payload };
    case "CLEAR_ME":
      return state;

    default:
      return state;
  }
}
