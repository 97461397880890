import React from "react";
import { Skeleton } from "antd";
import PropTypes from "prop-types";

// component
import NbSimpleCard from "../../nbSimpleCard/nbSimpleCard";

export function PostSkeleton({ loading, className }) {
  if (!loading) return null;
  return (
    <NbSimpleCard className={`mb4 ${className} `}>
      <Skeleton
        className={className}
        loading={loading}
        title
        active
        avatar={{
          size: 56,
        }}
        paragraph={{
          rows: 5,
        }}
      />
    </NbSimpleCard>
  );
}

PostSkeleton.defaultProps = {
  className: "",
};

PostSkeleton.propTypes = {
  loading: PropTypes.bool.isRequired,
  className: PropTypes.string,
};
