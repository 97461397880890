/* eslint-disable default-param-last */
import {
  ALL_POSTS,
  ALL_POSTS_SUCCESS,
  ALL_POSTS_FAILURE,
  ALL_POSTS_REMOVE,
  GET_NEIGHBOURS,
  GET_NEIGHBOURS_SUCCESS,
  GET_NEIGHBOURS_FAILURE,
  GET_CATEGORIES,
  GET_CATEGORIES_SUCCESS,
  GET_CATEGORIES_FAILURE,
  GET_CHILD_CATEGORIES,
  GET_CHILD_CATEGORIES_SUCCESS,
  GET_CHILD_CATEGORIES_FAILURE,
} from "../constants";

const initialState = {
  allPosts: [],
  allPostsLoader: true,
  allPostsError: null,

  neighbours: [],
  neighboursLoader: false,
  neighboursError: null,

  categories: [],
  categoriesLoader: false,
  categoriesError: null,

  childCategories: {
    message: [],
    classified: [],
    recommendation: [],
  },
  childCategoriesLoader: false,
  childCategoriesError: null,
};

export default function postReducer(state = initialState, action) {
  switch (action.type) {
    // Get All Posts
    case ALL_POSTS:
      return {
        ...state,
        allPosts: [],
        allPostsLoader: true,
        allPostsError: null,
      };

    case ALL_POSTS_SUCCESS:
      return {
        ...state,
        allPosts: action.payload,
        allPostsLoader: false,
        allPostsError: null,
      };

    case ALL_POSTS_FAILURE:
      return {
        ...state,
        allPosts: [],
        allPostsLoader: false,
        allPostsError: action.error,
      };
    case ALL_POSTS_REMOVE:
      return {
        ...state,
        allPosts: [],
        allPostsLoader: false,
        allPostsError: null,
      };

    // Get Neighbours
    case GET_NEIGHBOURS:
      return {
        ...state,
        neighbours: null,
        neighboursLoader: true,
        neighboursError: null,
      };

    case GET_NEIGHBOURS_SUCCESS:
      return {
        ...state,
        neighbours: action.payload,
        neighboursLoader: false,
        neighboursError: null,
      };

    case GET_NEIGHBOURS_FAILURE:
      return {
        ...state,
        neighbours: null,
        neighboursLoader: false,
        neighboursError: action.error,
      };

    // Get Categories
    case GET_CATEGORIES:
      return {
        ...state,
        categories: null,
        categoriesLoader: true,
        categoriesError: null,
      };

    case GET_CATEGORIES_SUCCESS:
      return {
        ...state,
        categories: action.payload,
        categoriesLoader: false,
        categoriesError: null,
      };

    case GET_CATEGORIES_FAILURE:
      return {
        ...state,
        categories: null,
        categoriesLoader: false,
        categoriesError: action.error,
      };

    // Get Child Categories
    case GET_CHILD_CATEGORIES:
      return {
        ...state,
        childCategoriesLoader: true,
        childCategoriesError: null,
      };

    case GET_CHILD_CATEGORIES_SUCCESS:
      return {
        ...state,
        childCategories: { ...state.childCategories, ...action.payload },
        childCategoriesLoader: false,
        childCategoriesError: null,
      };

    case GET_CHILD_CATEGORIES_FAILURE:
      return {
        ...state,
        childCategories: {},
        childCategoriesLoader: false,
        childCategoriesError: action.error,
      };
    default:
      return state;
  }
}
