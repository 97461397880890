import { Get } from "../../requests";

const globalSearching = (params) => {
  if (params) {
    return Get(`/search?q=${params}`);
  } else {
    return Get(`/search?q=${null}`);
  }
};

const GlobalSearchAPI = {
  globalSearching,
};

export default GlobalSearchAPI;
