import React from "react";

function SideIcon({ Icon, containerClassName, iconClassName }) {
  if (!Icon) return null;
  return (
    <div className={`journey-icon-container ${containerClassName ?? ""}`}>
      <Icon className={`journey-icon ${iconClassName ?? ""}`} />
    </div>
  );
}

export default SideIcon;
