import React from "react";
import PropTypes from "prop-types";

// scss
import "./nbDivider.scss";

function NbDivider({ noStyle, className, style }) {
  return (
    <div
      style={style}
      className={`nb-divider ${className} ${
        noStyle ? "" : "nb-divider-with-style"
      }`}
    />
  );
}

export default NbDivider;

NbDivider.defaultProps = {
  className: "",
  style: {},
  noStyle: true,
};

NbDivider.propTypes = {
  className: PropTypes.string,
  style: PropTypes.any,
  noStyle: PropTypes.bool,
};
