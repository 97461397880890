import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import {
  Col,
  Layout,
  Row,
  Menu,
  Avatar,
  Popover,
  notification,
  Affix,
  Drawer,
  Dropdown,
  Badge,
} from "antd";
import { UserOutlined, MessageOutlined, MailOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import debounce from "lodash/debounce";

// components
import Search from "../search/search";
import AuthGuard from "../../../../components/authGuard/authGuard";
import HomeSider from "../../../../pages/home/homeSider/homeSider";
import Container from "../container/container";
import NBGlobalProgress from "../nbGlobalProgress";
import NbSimpleCard from "../nbSimpleCard/nbSimpleCard";
import GlobalSearchAPI from "../../../../api/services/globalSearch/globalSearch";
import PostHeadInfo from "../nbUserCardInfo/nbUserCardInfo";
import NbDivider from "../nbDivider/nbDivider";
import Notification from "./notification";

// hooks
import { useNbRoutes } from "../../../../utils";

// icons
import { ReactComponent as BellIcon } from "../../../icons/bell.svg";
import { ReactComponent as HomeIcon } from "../../../icons/home.svg";
import { ReactComponent as NoticeboardIcon } from "../../../icons/noticeboard.svg";
import { ReactComponent as MessagesIcon } from "../../../icons/messages.svg";
import { ReactComponent as SearchIcon } from "../../../icons/search.svg";
import { ReactComponent as DrawerIcon } from "../../../icons/drawer.svg";
import { ReactComponent as MinimalLogo } from "../../../images/cc.svg";
import { ReactComponent as SettingIcon } from "../../../icons/draw.svg";
import { ReactComponent as ShieldIcon } from "../../../icons/shield.svg";
import { ReactComponent as HelpIcon } from "../../../icons/help.svg";
import { ReactComponent as CommunityFullLogo } from "../../../images/fulllogo.svg";

// icons
import { CrossIcon } from "../../../icons/createPost";

// actions
import tokenActions from "../../../../store/auth/action";

// apis
import authApis from "../../../../api/services/auth/auth";

// scss
import "./header.scss";
import { ALL_POSTS_REMOVE } from "../../../../store/constants";

const { Header } = Layout;

function Content() {
  const dispatch = useDispatch();
  const nbRoutes = useNbRoutes();
  const logout = async () => {
    try {
      await authApis.userLogout();
      dispatch(tokenActions.Token(null));
      dispatch(tokenActions.clearMe());
      window.localStorage.removeItem("signupAuthToken");
      if (nbRoutes.private) {
        nbRoutes.gotoLogin();
      }
      dispatch({
        type: ALL_POSTS_REMOVE,
      });
    } catch (err) {
      notification.error({
        message: err?.message || "Something went wrong!",
      });
    }
  };
  return (
    <div className="pop-menu-content">
      <Link
        to="/account/settings"
        className="pop-menu-content-item c-pointer nb-base-text"
      >
        <div className="pop-menu-content-item-icon-1">
          <ShieldIcon height="24px" width="24px" className="" />
        </div>
        <div className="pop-menu-content-item-label">Settings and Privacy</div>
        <div className="pop-menu-content-item-icon-2">
          <img src={BellIcon} alt="" />
        </div>
      </Link>

      <Link
        to="#"
        className="pop-menu-content-item nb-base-text"
        onClick={() => logout()}
      >
        <div className="pop-menu-content-item-icon-1">
          <HelpIcon />
        </div>
        <div className="pop-menu-content-item-label">Logout</div>
      </Link>
    </div>
  );
}

function AppHeaderRight() {
  const {
    user_id: userId,
    first_name: firstName,
    last_name: lastName,
    profile_image: profileImage,
    default_display_id: defaultDisplayId,
    default_display: defaultDisplay,
  } = useSelector((state) => state.me || {});
  const me = useSelector((state) => state.me);
  let userAvatar = userId === defaultDisplayId ? profileImage : null;
  if (defaultDisplay?.logo?.url) {
    userAvatar = defaultDisplay.logo.url;
  }
  const avatarName = `${me?.first_name?.charAt(0)?.toUpperCase()}${me?.last_name
    ?.charAt(0)
    ?.toUpperCase()}`;

  return (
    <div className="app-header-menu">
      <div key="notification" className="app-header-menu-item item-2">
        <Notification />
      </div>

      <div
        key="mail"
        className="app-header-menu-item app-header-user-edit-profile px2 pb2 pt2"
      >
        <Link to="/profile">
          <div className="display-flex align-items-center">
            {userAvatar ? (
              <Badge
                size="default"
                style={{ backgroundColor: "#3155a6" }}
                text
                offset={[-12, 5]}
                count={me?.status === "temp_verified" ? "L" : null}
              >
                <Avatar size={40} src={userAvatar} className="mr2" />
              </Badge>
            ) : (
              <Badge
                style={{ backgroundColor: "#3155a6" }}
                text
                offset={[-12, 5]}
                count={me?.status === "temp_verified" ? "L" : null}
              >
                <Avatar
                  style={{
                    backgroundColor: "#3155a6",
                    marginRight: "5px",
                  }}
                  size="large"
                >
                  {avatarName}
                </Avatar>
              </Badge>
            )}
            <div className="header-profile-image-text">
              {defaultDisplay?.name
                ? defaultDisplay.name
                : `${firstName} ${lastName}`}
            </div>
          </div>
        </Link>
      </div>

      <div key="setting" className="app-header-menu-item">
        <Popover
          overlayClassName="app-header-popover"
          content={<Content />}
          title={null}
          placement="bottomRight"
          trigger="click"
        >
          <SettingIcon data-testid="more-menu-button" />
          {/* <div className="mt1 setting-label">Settings</div> */}
        </Popover>
      </div>
    </div>
  );
}

function AppHeader({ className }) {
  const [searchData, setSearchData] = useState(null);
  const [searchLoader, setSearchLoader] = useState(null);
  const [searchBar, setSearchBar] = useState(false);
  const [searchString, setSearchString] = useState("");
  const [mobileSearch, setMobileSearch] = useState(false);
  const history = useHistory();
  const {
    first_name: firstName,
    last_name: lastName,
    profile_image: profileImage,
    user_id: userId,
    default_display_id: defaultDisplayId,
    default_display: defaultDisplay,
  } = useSelector((state) => state.me || {});
  const nbRoutes = useNbRoutes();
  const [visible, setVisible] = useState(false);
  const { progressLoading } = useSelector((state) => state.globalComponents);
  const me = useSelector((state) => state.me);
  let userAvatar = userId === defaultDisplayId ? profileImage : null;
  if (defaultDisplay?.logo?.url) {
    userAvatar = defaultDisplay.logo.url;
  }
  const avatarName = `${me?.first_name?.charAt(0)?.toUpperCase()}${me?.last_name
    ?.charAt(0)
    ?.toUpperCase()}`;

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  const searchChange = debounce(async (value) => {
    try {
      let searchValue = "";
      if (value.trim() !== "") {
        searchValue = value;
      } else if (!value || value === "") {
        setSearchBar(false);
      }
      if (!searchValue) return;
      setSearchLoader(true);
      setSearchString(searchValue);
      const res = await GlobalSearchAPI.globalSearching(searchValue);
      setSearchData(res);
      setSearchLoader(false);
      setSearchBar(true);
      if (
        (!value && res?.data?.posts.length) ||
        res?.data?.users.length ||
        res?.data?.comments.length
      ) {
        setSearchBar(false);
      }
    } catch (error) {
      setSearchLoader(false);
    }
  }, 300);

  const searchEnter = (e) => {
    if (!searchLoader) {
      history.push({
        pathname: "/global-search",
        state: { string: e.target.value },
      });
      setSearchBar(false);
    }
  };

  const searchOverLay = () => {
    return (
      <NbSimpleCard className="search-section nb-nice-scroll-style">
        {searchData?.data?.users?.data?.length ? (
          <>
            <div className="display-flex">
              <UserOutlined style={{ fontSize: "15px" }} className="mt1 mr2" />
              <h6 className="nb-primary-texts m0">Community Members</h6>
            </div>
            <NbDivider className="apply-bottom-border-grey" />
          </>
        ) : null}
        {searchData?.data?.users?.data?.slice(0, 2)?.map((el) => {
          return (
            <>
              <div
                onClick={() => {
                  setSearchBar(false);
                }}
                role="button"
                tabIndex="0"
                onKeyPress={(event) => event.key === "enter" && onClick()}
              >
                <Link to={`/profile/${el?.user_id}`}>
                  <PostHeadInfo
                    avatarSize={40}
                    name={
                      <p className="mb0">{`${el.first_name} ${el.last_name}`}</p>
                    }
                    imageUrl={el?.profile_image}
                    data={el}
                  />
                </Link>
              </div>
              <NbDivider className="apply-bottom-border-grey" />
            </>
          );
        })}
        {searchData?.data?.posts?.data?.length ? (
          <>
            <div className="display-flex">
              <MessageOutlined
                style={{ fontSize: "20px" }}
                className="mt1 mr2"
              />
              <h6 className="nb-primary-texts m0">Posts</h6>
            </div>
            <NbDivider className="apply-bottom-border-grey" />
          </>
        ) : null}
        {searchData?.data?.posts?.data?.slice(0, 2)?.map((el) => {
          return (
            <div
              className="search-content-text"
              onClick={() => {
                setSearchBar(false);
              }}
              role="button"
              tabIndex="0"
              onKeyPress={(event) => event.key === "enter" && onClick()}
              key={el?.post_id}
            >
              <Link to={`/post/view/${el?.post_id}`}>
                <p className="fnw-700 mb0 truncate">{el?.title}</p>
                <p className="nb-faded-text truncate mb0">{el?.content}</p>
              </Link>
              <NbDivider className="apply-bottom-border-grey" />
            </div>
          );
        })}
        {searchData?.data?.comments?.data.length ? (
          <>
            <div className="display-flex">
              <MailOutlined style={{ fontSize: "20px" }} className="mt1 mr2" />
              <h6 className="nb-primary-texts m0">Replies</h6>
            </div>
            <NbDivider noStyle={false} />
          </>
        ) : null}
        <Link to="#">
          <>
            {searchData?.data?.comments?.data?.splice(0, 2)?.map((el) => {
              return (
                <div
                  onClick={() => {
                    setSearchBar(false);
                  }}
                  role="button"
                  tabIndex="0"
                  onKeyPress={(event) => event.key === "enter" && onClick()}
                  key={el?.post?.post_id}
                >
                  <Link to={`/post/view/${el?.post?.post_id}`}>
                    <p className="fnw-700 mb0 truncate">{el?.post?.title}</p>
                    <p className="nb-faded-text truncate">{el?.body}</p>
                  </Link>
                  <NbDivider noStyle={false} />
                </div>
              );
            })}

            {searchData?.data?.business?.data?.length ? (
              <>
                <div className="display-flex">
                  <UserOutlined
                    style={{ fontSize: "20px" }}
                    className="mt1 mr2"
                  />
                  <h6 className="nb-primary-text m0">Business</h6>
                </div>
                <NbDivider noStyle={false} />
              </>
            ) : null}
            {searchData?.data?.business?.data?.splice(0, 2)?.map((el) => {
              return (
                <div
                  onClick={() => {
                    setSearchBar(false);
                  }}
                  role="button"
                  tabIndex="0"
                  onKeyPress={(event) => event.key === "enter" && onClick()}
                  key={el.organisation_id}
                >
                  <Link to={`/business/${el.organisation_id}/details`}>
                    <>
                      <p className="fnw-700 mb0 truncate">{el?.name}</p>
                      <p className="nb-faded-text truncate">
                        {el?.description}
                      </p>
                    </>
                  </Link>
                  <NbDivider noStyle={false} />
                </div>
              );
            })}

            {searchData?.data?.organisation?.data?.length ? (
              <>
                <div className="display-flex">
                  <UserOutlined
                    style={{ fontSize: "20px" }}
                    className="mt1 mr2"
                  />
                  <h6 className="nb-primary-text m0">Organisation</h6>
                </div>
                <NbDivider noStyle={false} />
              </>
            ) : null}
            {searchData?.data?.organisation?.data?.splice(0, 2)?.map((el) => {
              return (
                <div
                  key={el.organisation_id}
                  onClick={() => {
                    setSearchBar(false);
                  }}
                  role="button"
                  tabIndex="0"
                  onKeyPress={(event) => event.key === "enter" && onClick()}
                >
                  <Link to={`/organisation/${el.organisation_id}/details`}>
                    <>
                      <p className="fnw-700 mb0 truncate">{el?.name}</p>
                      <p className="nb-faded-text truncate">
                        {el?.description}
                      </p>
                    </>
                  </Link>
                  <NbDivider noStyle={false} />
                </div>
              );
            })}

            {searchData?.data?.publication?.data?.length ? (
              <>
                <div className="display-flex">
                  <UserOutlined
                    style={{ fontSize: "20px" }}
                    className="mt1 mr2"
                  />
                  <h6 className="nb-primary-text m0">Publication</h6>
                </div>
                <NbDivider noStyle={false} />
              </>
            ) : null}
            {searchData?.data?.publication?.data?.splice(0, 2)?.map((el) => {
              return (
                <div
                  key={el.organisation_id}
                  onClick={() => {
                    setSearchBar(false);
                  }}
                  role="button"
                  tabIndex="0"
                  onKeyPress={(event) => event.key === "enter" && onClick()}
                >
                  <Link to={`/publication/${el.organisation_id}`}>
                    <>
                      <p className="fnw-700 mb0 truncate">{el?.name}</p>
                      <p className="nb-faded-text truncate">
                        {el?.description}
                      </p>
                    </>
                  </Link>
                  <NbDivider noStyle={false} />
                </div>
              );
            })}

            {searchData?.data?.users?.data?.length ||
            searchData?.data?.posts?.data?.length ? (
              <div
                style={{ textAlign: "center" }}
                className="show-more-btn pt3 fw-600"
              >
                <button
                  style={{ margin: "auto" }}
                  onClick={() => {
                    setSearchBar(false);
                    localStorage.setItem("searchString", searchString);
                    history.push({
                      pathname: "/global-search",
                    });
                  }}
                  type="button"
                  className="nb-proxy-button c-pointer"
                >
                  Show more
                </button>
              </div>
            ) : (
              <div style={{ textAlign: "center" }}>
                <p className="mb0 nb-faded-text">No content found</p>
              </div>
            )}
          </>
        </Link>
      </NbSimpleCard>
    );
  };

  return (
    <>
      <NBGlobalProgress
        isAnimating={progressLoading.isAnimating}
        key={progressLoading.key}
      />
      <Header className={`app-header-mobile ${className}`}>
        <Menu
          mode="horizontal"
          className="app-header-mobile-menu"
          items={[
            {
              key: "1",
              className: "app-header-mobile-menu-item mobile-menu-item-1",
              label: (
                <Link
                  to="#"
                  className="nb-base-text"
                  onClick={() => nbRoutes.gotoRoot()}
                >
                  <div>
                    <MinimalLogo className="app-header-mobile-logo" />
                  </div>
                </Link>
              ),
            },
            {
              key: "3",
              className: "app-header-mobile-menu-item",
              label: (
                <Link to="/message" className="nb-base-text">
                  <div>
                    <MessagesIcon />
                    <span className="app-header-mobile-menu-item-heading mt2">
                      Messages
                    </span>
                  </div>
                </Link>
              ),
            },
            {
              key: "4",
              className: "app-header-mobile-menu-item",
              label: (
                <div>
                  <Notification />
                </div>
              ),
            },
            {
              key: "5",
              className: "app-header-mobile-menu-item",
              label: (
                <div>
                  <SearchIcon
                    onClick={() => {
                      setMobileSearch(true);
                    }}
                  />
                  <span className="app-header-mobile-menu-item-heading mt2">
                    Search
                  </span>
                </div>
              ),
            },
          ]}
        />
      </Header>
      <div
        className={
          mobileSearch
            ? "mobile-search-bar-block mobile-search-bar"
            : "mobile-search-bar-inline mobile-search-bar"
        }
      >
        <CrossIcon
          onClick={() => {
            setMobileSearch(false);
          }}
          className="iconCross"
        />
        <Dropdown
          placement="bottomRight"
          open={searchBar}
          overlayClassName={
            mobileSearch
              ? "overlay-search-mobile dropdown-open mnw300 search-dropdown"
              : "overlay-search-mobile dropdown-close mnw300 search-dropdown"
          }
          overlay={searchOverLay()}
          // overlay={<div />}
          overlayStyle={{ position: "fixed" }}
        >
          <Search
            onChange={(value) => searchChange(value)}
            onPressEnter={searchEnter}
            searchLoader={searchLoader}
            className=""
          />
        </Dropdown>
      </div>
      <Header className={`app-header ${className}`}>
        <Row>
          <Col span={12} className="app-header-left">
            {/* <div className="app-header-left-logo">
              <h5 className="margin-0">
                <Link onClick={() => nbRoutes.gotoRoot()} to="#">
                  <CommunityFullLogo />
                </Link>
              </h5>
            </div> */}
            <Dropdown
              placement="bottomLeft"
              open={searchBar}
              overlayClassName="mnw300 search-dropdown"
              overlayStyle={{ position: "fixed" }}
              overlay={searchOverLay()}
              // overlay={<div />}
            >
              <Search
                onChange={(value) => searchChange(value)}
                onPressEnter={searchEnter}
                searchLoader={searchLoader}
                className=""
              />
            </Dropdown>
          </Col>
          <AuthGuard
            authActions={{
              public: {
                action: "HIDE",
              },
            }}
          >
            <Col span={12} className="app-header-right">
              <AppHeaderRight />
            </Col>
          </AuthGuard>
        </Row>
      </Header>
    </>
  );
}

function AffixHeader({ className, contianerStyle }) {
  return (
    <Affix offsetTop={0.1} className="affix-header">
      <Container style={{ padding: 0, ...contianerStyle }} fluid>
        <AppHeader className={className} />
      </Container>
    </Affix>
  );
}

export default AffixHeader;
