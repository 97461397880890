/* eslint-disable react/no-danger */
import { Avatar } from "antd";
import PropTypes from "prop-types";

function NbAvatar({ profileImage, firstName, lastName, size }) {
  const avatarName = `${firstName?.charAt(0)?.toUpperCase()}${lastName
    ?.charAt(0)
    ?.toUpperCase()}`;

  return profileImage ? (
    <Avatar
      style={{
        marginRight: "5px",
      }}
      src={profileImage}
      size={size}
    />
  ) : (
    <Avatar
      style={{
        backgroundColor: "#3155a6",
        marginRight: "5px",
      }}
      size={size}
    >
      {avatarName}
    </Avatar>
  );
}

export default NbAvatar;

NbAvatar.defaultProps = {
  profileImage: "",
  firstName: "",
  lastName: "",
  size: undefined,
};

NbAvatar.propTypes = {
  profileImage: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  size: PropTypes.number,
};
