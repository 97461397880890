import React from "react";
import { Skeleton } from "antd";
import PropTypes from "prop-types";

// scss
import "./roundImageSkeleton.scss";

export function RoundImageSkeleton({ loading, className }) {
  if (!loading) return null;

  return (
    <Skeleton.Avatar
      active={loading}
      className={`round-image-skeleton ${className}`}
    />
  );
}

RoundImageSkeleton.defaultProps = {
  className: "",
};

RoundImageSkeleton.propTypes = {
  loading: PropTypes.bool.isRequired,
  className: PropTypes.string,
};
