import { SET_WIDGETS_DATA, SET_WIDGETS_DATA_LOADING } from "../constants";

const initialState = {
  isLoading: false,
};

// eslint-disable-next-line default-param-last
export default function widgetsReducer(state = initialState, action) {
  switch (action.type) {
    case SET_WIDGETS_DATA:
      return { ...state, ...action.payload };
    case SET_WIDGETS_DATA_LOADING:
      return { ...state, isLoading: action.payload };
    default:
      return state;
  }
}
