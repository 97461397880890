import React from "react";
import { Button } from "antd";
import PropTypes from "prop-types";

// components
import NbSimpleCard from "../../assets/gobal/components/nbSimpleCard/nbSimpleCard";

function PostHidden({ className, message, onUnHide }) {
  return (
    <NbSimpleCard
      className={`nb-post-hidden p4 mb4 display-flex justify-content-space-between align-items-center ${className}`}
    >
      <p className="mb0">{message}</p>
      {onUnHide && (
        <Button type="primary" className="nb-button" onClick={onUnHide}>
          Undo
        </Button>
      )}
    </NbSimpleCard>
  );
}

export default PostHidden;

PostHidden.defaultProps = {
  className: "",
  message: "This post has been hidden",
  onUnHide: undefined,
};

PostHidden.propTypes = {
  className: PropTypes.string,
  message: PropTypes.string,
  onUnHide: PropTypes.func,
};
