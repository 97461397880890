import React from "react";
import { useNProgress } from "@tanem/react-nprogress";

import Bar from "./bar";
import Container from "./container";

export default function NBGlobalProgress({ isAnimating }) {
  const { animationDuration, isFinished, progress } = useNProgress({
    isAnimating,
    incrementDuration: 0,
  });

  return (
    <Container animationDuration={animationDuration} isFinished={isFinished}>
      <Bar animationDuration={animationDuration} progress={progress} />
    </Container>
  );
}
