import React from "react";
import { Input } from "antd";

// icons
import SideIcon from "../sideIcon";
import EmojiMart from "../emojiMart/emojiMart";
import { formatNumber } from "../../../../utils";

const { TextArea } = Input;

export default function TextAreaField(props) {
  const {
    placeholder,
    minRows,
    maxRows,
    className,
    showEmojis,
    maxLength,
    onChange,
    onEmojiChange,
    value,
    name,
    required,
    disabled,
    pattern,
    containerClassName,
    showCharCount,
    leftIcon,
    leftIconContainerClassName,
    leftIconClass,
    isTextArea,
  } = props;

  const formattedValueLength = formatNumber(value?.length);
  const formattedMaxLength = formatNumber(maxLength);

  return (
    <div className={`custom-input-container ${containerClassName ?? ""}`}>
      <div className="display-flex align-items-center w-p100">
        <SideIcon
          Icon={leftIcon}
          containerClassName={`pl6 display-flex align-items-center ${
            leftIconContainerClassName ?? ""
          }`}
          iconClassName={leftIconClass}
        />
        {isTextArea ? (
          <TextArea
            placeholder={placeholder}
            autoSize={{ minRows, maxRows }}
            className={`custom-input ${className ?? ""}`}
            maxLength={maxLength}
            onChange={onChange}
            value={value}
            name={name}
            required={required}
            disabled={disabled}
          />
        ) : (
          <Input
            placeholder={placeholder}
            className={`custom-input ${className ?? ""}`}
            maxLength={maxLength}
            onChange={onChange}
            value={value}
            name={name}
            required={required}
            disabled={disabled}
            pattern={pattern}
          />
        )}
      </div>
      {(showCharCount || showEmojis) && (
        <div className="max-char-container">
          {showCharCount && (
            <p className="max-char-text">{`${formattedValueLength} / ${formattedMaxLength}`}</p>
          )}
          {!disabled && showEmojis && <EmojiMart onClick={onEmojiChange} />}
        </div>
      )}
    </div>
  );
}
