const Token = (payload) => {
  return {
    type: "TOKEN",
    payload,
  };
};

const saveMe = (payload) => {
  return {
    type: "ME",
    payload,
  };
};

const clearMe = () => {
  return {
    type: "CLEAR_ME",
  };
};

const tokenActions = {
  Token,
  saveMe,
  clearMe,
};

export default tokenActions;
