import React from "react";
import PropTypes from "prop-types";
import { Form, Input, Button, Alert } from "antd";

// scss
import "./loginForm.scss";

export default function LoginForm({
  onFinish,
  apiResponse,
  loading,
  className,
}) {
  return (
    <div className={`login ${className}`}>
      <h4 className="login-title">Welcome to Communities</h4>
      <p className="inline-block mb5 login-link">Please login to continue</p>
      {apiResponse?.message && (
        <Alert
          className="mb4"
          type="error"
          showIcon
          message={apiResponse?.message}
        />
      )}
      <Form
        layout="vertical"
        name="login"
        validateTrigger={["onSubmit"]}
        onFinish={onFinish}
      >
        <Form.Item
          label="Enter Email  Address"
          name="email"
          rules={[
            {
              required: true,
              message: "Please enter your email address",
            },
            {
              type: "email",
              message: "Email is invalid",
            },
          ]}
        >
          <Input
            size="large"
            placeholder="Email  Address"
            className="nb-text-input"
          />
        </Form.Item>
        <Form.Item
          label="Password"
          name="password"
          rules={[{ required: true, message: "Please enter your password" }]}
        >
          <Input.Password
            className="nb-text-input"
            size="large"
            type="password"
            placeholder="Password"
          />
        </Form.Item>
        <Form.Item>
          <Button
            className="nb-button"
            loading={loading}
            block
            type="primary"
            htmlType="submit"
          >
            Login Account
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
}

LoginForm.defaultProps = {
  apiResponse: null,
  className: "",
};

LoginForm.propTypes = {
  onFinish: PropTypes.func.isRequired,
  apiResponse: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.objectOf(PropTypes.object),
  ]),
  className: PropTypes.string,
};
