import React from "react";

export default function SimpleCard(props) {
  const { children, containerClass } = props;
  return (
    <div className={`nb-simple-card p4 mb4 ${containerClass ?? ""}`}>
      {children}
    </div>
  );
}
