/* istanbul ignore file */
import React, { memo } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import isEmpty from "lodash/isEmpty";

// hooks
import { useProfileSwitchingGuard } from "../../utils";

// constants
import { AUTH_ACTIONS } from "../../constants/miscellaneous";

function useStatus(status) {
  const switchingGuard = useProfileSwitchingGuard();
  let updatedStatus = status;
  if (status === "temp_verified") updatedStatus = "fully_verified"; //* temp verified and fully verified are same

  if (switchingGuard.isOrganisationOnlyProfile())
    updatedStatus = "fully_verified"; //* temp verified and fully verified are same

  if (switchingGuard.isBusinessOnlyProfile()) updatedStatus = "fully_verified"; //* temp verified and fully verified are same

  return updatedStatus;
}

function AuthGuard({ children, authActions }) {
  let { status = "public" } = useSelector((state) => state.me || {});
  const updatedStatus = useStatus(status);
  status = updatedStatus;

  if (!authActions && isEmpty(authActions)) {
    // * return if no action is passed, sort of error handling
    return children;
  }

  if (authActions[status]?.action) {
    if (authActions[status].action === AUTH_ACTIONS.HIDE) {
      return null;
    } else if (authActions[status].action === AUTH_ACTIONS.DEFFER_CLICKS) {
      return React.cloneElement(children, {
        onClick: (e) => null,
      });
    } else if (authActions[status].action === AUTH_ACTIONS.REPLACE_CHILDREN) {
      return authActions[status]?.component;
    }
  } else {
    return children;
  }
}

export default memo(AuthGuard);

AuthGuard.defaultProps = {
  authActions: undefined,
};

AuthGuard.propTypes = {
  children: PropTypes.any.isRequired,
  authActions: PropTypes.shape({
    public: PropTypes.shape({
      action: PropTypes.string,
    }),
    fully_verified: PropTypes.shape({
      action: PropTypes.string,
      component: PropTypes.element,
    }),
  }),
};
