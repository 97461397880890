import React, { useState, useRef } from "react";
import PropTypes from "prop-types";

// components
import AuthGuard from "../../authGuard/authGuard";

// icons
import {
  Like,
  Comment,
  LikeFilled,
  Heart,
  Clap,
  Pensive,
} from "../../../assets/icons/post";

// scss
import "./reactionButtons.scss";

function ReactionButtons({
  style,
  className,
  onClickComment,
  isLiked,
  likesCount,
  onReact,
  showComment,
  onUnReact,
  commentButton,
  uniqueReacts,
  commentsDisabled,
}) {
  const [reaction, setReaction] = useState(isLiked);
  const [count, setCount] = useState(likesCount);
  const topReacts = uniqueReacts?.sort((a, b) => b.count - a.count);
  const emojis = useRef([
    { icon: LikeFilled, key: "thumbs_up" },
    { icon: Heart, key: "heart" },
    { icon: Clap, key: "clap" },
    // { icon: Tada, key: "tada" },
    { icon: Pensive, key: "pensive" },
  ]);
  const emoji = [
    { icon: LikeFilled, name: "thumbs_up" },
    { icon: Heart, name: "heart" },
    { icon: Clap, name: "clap" },
    { icon: Pensive, name: "pensive" },
  ];
  const topReactsData = emoji.filter((data) =>
    topReacts?.some((topReact) => topReact.name === data.name)
  );

  const getReaction = (key) => {
    if (!key) {
      return <Like className="post-reaction-button-icons mr1" />;
    } else {
      const selectedEmoji = emojis.current.find((value) => value.key === key);
      const Emoji = selectedEmoji?.icon;
      return <Emoji className="post-reaction-button-icons mr1" />;
    }
  };

  const onReactLocal = (key, reactionCase) => {
    if (reactionCase === "thumb_reaction") {
      if (reaction) {
        setCount(count - 1);
        setReaction(null);
        onUnReact();
      } else {
        setCount(count + 1);
        setReaction(key);
        onReact(key);
      }
    } else if (reaction) {
      setReaction(key);
      onReact(key);
    } else {
      setCount(count + 1);
      setReaction(key);
      onReact(key);
    }
  };

  return (
    <div>
      {topReactsData.length > 1 && (
        <div className="post-reacts-emoji display-flex mb3">
          {topReactsData.slice(0, 3).map(({ icon: Emoji, key }) => {
            return (
              <Emoji
                key={`emoji-${key}`}
                className="post-reaction-button-icons mr1"
              />
            );
          })}
          <span>{count === 0 ? null : count}</span>
        </div>
      )}
      <div className={`post-reaction-button flex1 ${className}`} style={style}>
        <div className="nb-faded-text fn8 c-pointer post-like-button">
          <button
            type="button"
            className="nb-proxy-button c-pointer"
            onClick={() => {
              onReactLocal("thumbs_up", "thumb_reaction");
            }}
          >
            {getReaction(reaction)}
            {topReactsData.length === 1 && (count === 0 ? null : count)}
          </button>
          <div className="post-reacts-emojis">
            {emojis.current.map(({ icon: Emoji, key }) => {
              return (
                <Emoji
                  key={`emoji-${key}`}
                  onClick={() => {
                    onReactLocal(key, "emoji_reaction");
                  }}
                  className="post-reaction-button-icons mr1"
                />
              );
            })}
          </div>
        </div>
        {commentButton ||
          (showComment && (
            <div className="nb-faded-text fn8 ml4 c-pointer">
              <AuthGuard
                authActions={{
                  not_verified: {
                    action: "BECOME_VERIFIED",
                  },
                }}
              >
                <button
                  type="button"
                  onClick={onClickComment}
                  className={` nb-proxy-button  ${
                    commentsDisabled ? "c-not-allowed" : "c-pointer"
                  }  `}
                  title={commentsDisabled ? "Comments has been Disabled" : ""}
                >
                  <Comment className="post-reaction-button-icons mr1" />
                  {/* {commentsDisabled ? "Disabled" : "Comment"} */}
                  Comment
                </button>
              </AuthGuard>
            </div>
          ))}
      </div>
    </div>
  );
}

export default ReactionButtons;

ReactionButtons.defaultProps = {
  style: {},
  className: "",
  isLiked: false,
  likesCount: 0,
  showComment: true,
  commentButton: undefined,
  commentsDisabled: false,
};

ReactionButtons.propTypes = {
  style: PropTypes.objectOf(PropTypes.object),
  className: PropTypes.string,
  onClickComment: PropTypes.func.isRequired,
  onReact: PropTypes.func.isRequired,
  onUnReact: PropTypes.func.isRequired,
  isLiked: PropTypes.any,
  likesCount: PropTypes.number,
  showComment: PropTypes.bool,
  commentButton: PropTypes.element,
  commentsDisabled: PropTypes.bool,
};
