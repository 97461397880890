import React from "react";
import { Skeleton } from "antd";

export default function NotificationSkeleton() {
  return (
    <div className="neighbour-listing-card-body nb-nice-scroll-style d-flex mt4 ">
      <div
        style={{ width: "360px" }}
        className="event-listing-skeleton neighbours-single px4 my4 display-flex"
      >
        <Skeleton.Avatar shape="circle" loading size={40} />
        <Skeleton
          loading
          title
          active
          className="px4"
          paragraph={{
            rows: 0,
          }}
        />
      </div>
      <div
        className="event-listing-skeleton neighbours-single px4 display-flex"
        fluid
      >
        <Skeleton.Avatar shape="circle" loading size={40} />
        <Skeleton
          loading
          title
          active
          className="px4"
          paragraph={{
            rows: 0,
          }}
        />
      </div>
    </div>
  );
}
