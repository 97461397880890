import React from "react";
import { useSelector } from "react-redux";
import { Radio, Checkbox } from "antd";
import PerfectScrollbar from "react-perfect-scrollbar";
import { ListCheckboxOtpion } from "../listOption";

function NeighboursList(props) {
  const {
    selectedNeighbour,
    selectedNearbyNeighbour,
    updatedSelectedNeighbour,
    updatedSelectedNearbyNeighbour,
    setPanelActive,
  } = props;

  const { neighbours } = useSelector((state) => state.post);
  const data = useSelector((state) => state.me);
  const neighbourhoodDetail = data.communities;

  let preSelectedNearbyNeighbours = [];

  if (neighbours?.nearby_neighbourhoods?.length) {
    preSelectedNearbyNeighbours = neighbours.nearby_neighbourhoods.map(
      (nearbyNeighbour) => ({
        id: nearbyNeighbour.nearby_neighbourhood_id,
        count: nearbyNeighbour.neighbourhood.member_count,
      })
    );
  }

  return (
    <Radio.Group
      className="w-100"
      value={selectedNeighbour}
      onChange={
        /* istanbul ignore next */
        (ev) => {
          const { value } = ev.target;
          if (value === 0) {
            setPanelActive(["1"]);
            updatedSelectedNearbyNeighbour([
              ...selectedNearbyNeighbour,
              neighbours.neighbourhood_id,
              ...preSelectedNearbyNeighbours.map((x) => x.id),
            ]);
          } else {
            setPanelActive([]);
            updatedSelectedNearbyNeighbour([]);
          }
          updatedSelectedNeighbour(value);
        }
      }
    >
      <ul className="neighbours-list-container  nb-nice-scroll-style mxh400 o-y-auto">
        <Checkbox.Group
          onChange={(keys) => updatedSelectedNearbyNeighbour(keys)}
          value={selectedNearbyNeighbour}
        >
          <PerfectScrollbar>
            {neighbourhoodDetail?.map((neighbourhood) => {
              return (
                <li
                  key={neighbourhood.neighbourhood_id}
                  className="communities-list-item "
                >
                  <ListCheckboxOtpion
                    value={neighbourhood.neighbourhood_id}
                    text={neighbourhood.name}
                  />
                </li>
              );
            })}
          </PerfectScrollbar>
        </Checkbox.Group>
      </ul>
    </Radio.Group>
  );
}

export default NeighboursList;
