import { Post, Get } from "../../requests";

const userLogin = (data) => {
  return Post("/auth/sign-in", data);
};

const userLogout = (data) => {
  return Post("/auth/sign-out", { device_type: "web" });
};

const signup = (data) => {
  return Post("/auth/sign-up", data);
};

const verifyNumber = (params, token) => {
  const header = {
    Authorization: `Bearer ${token}`,
  };
  return Post("/verify/phone", params, header);
};

const verrifyDocument = (params, token) => {
  const header = {
    Authorization: `Bearer ${token}`,
  };
  return Post("/verify/document", params, header);
};

const verrifyDrivingLicence = (params, token) => {
  const header = {
    Authorization: `Bearer ${token}`,
  };
  return Post("/verify/driving-licence", params, header);
};
const forgotPassword = (data) => {
  return Post("/auth/forgot-password", data);
};
const forgotPasswordByPhone = (data) => {
  return Post("/auth/forgot-email", data);
};
const forgotVerificationCode = (data) => {
  return Post("/auth/verify-verification_code", { verification_code: data });
};

const resetPasswordVerification = (data) => {
  return Post("/auth/reset-password", data);
};

const resentMobileVerificationCode = (token) => {
  const header = {
    Authorization: `Bearer ${token}`,
  };
  return Post("/resend/verification-message", null, header);
};

const getMe = (token) => {
  const header = {
    Authorization: `Bearer ${token}`,
  };
  return Get("/me", header);
};

const switchProfile = (data) => {
  return Post("/switch-profile/", data);
};

const authApis = {
  userLogin,
  signup,
  userLogout,
  verifyNumber,
  verrifyDocument,
  verrifyDrivingLicence,
  forgotPassword,
  forgotVerificationCode,
  resetPasswordVerification,
  forgotPasswordByPhone,
  resentMobileVerificationCode,
  getMe,
  switchProfile,
};
export default authApis;
