import moment from "moment";

// constants
import { POST_TYPES } from "../constants";

export const dateStringFormat1 = "YYYY-MM-DD HH:mm";
export const dateStringFormat2 = "hh:mm a, MM[/]DD[/]YYYY";
export const monthFormat = "MM/YY";
export const websiteRegexWithHttps =
  /(ftp|http|https):\/\/((?:www\.|(?!www))[A-Z0-9][A-Z0-9-]+[A-Z0-9]\.[a-z]{2,4})/gi;
export const websiteRegex =
  /(^!((ftp|http|https):\/\/)|^(?:www\.|(?!www))[A-Z0-9][A-Z0-9-]+[A-Z0-9]\.[a-z]{2,4})/gi;

export const websiteRegexWithoutHttps =
  /(^!?((ftp|http|https):\/\/)|^(?:www\.|(?!www))[A-Z0-9][A-Z0-9-]+[A-Z0-9]\.[a-z]{2,4})/gi;
export const emailWithWebsiteRegex =
  /(^[\w-\\.]+@([\w-]+\.)+[\w-]{2,4}$)|((www\.)[a-zA-Z0-9-]+[a-zA-Z0-9]\.[a-z]{2,4})/g;
export const emailRegex = /^[\w-\\.]+@([\w-]+\.)+[\w-]{2,4}$/g;
export const phoneRegex =
  // /^[+]*\({0,1}[0-9]{1,4}\){0,1}[-\s\\./0-9]*$/g
  /^\+?\d{1,3}[-. ]?\(?\d{2,4}\)?[-. ]?\d{3,5}[-. ]?\d{3,4}$/g;
export const numberRegex = /^\d+$/g;

export const uploadAccept =
  "application/pdf, image/jpeg, image/jpg, image/png, .doc, .docx";
export const uploadAcceptOnlyImages = "image/jpeg, image/jpg, image/png";
export const uploadAcceptWithVideo =
  "application/pdf, image/jpeg, image/jpg, image/png, video/mp4,video/x-m4v,video/*";

export const formatStringForKey = (text) => {
  if (typeof text === "number") return text;
  return text && text.trim() ? text.trim().replace(/ /g, "") : "";
};

export function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
}

export function getFormattedDateWithTime(date, time, format) {
  const pickedDate = moment(date);
  if (time) {
    pickedDate.set({
      hour: time.get("hour"),
      minute: time.get("minute"),
    });
  }
  return pickedDate.format(format);
}

export function getDisabledHours(time) {
  const currentHour = moment(time).format("HH");
  const disabledHoursList = [];
  for (let index = 0; index < 24; index += 1) {
    if (currentHour > index) {
      disabledHoursList.push(index);
    }
  }
  return disabledHoursList;
}

export function getDisabledMinutes(time) {
  const currentMinute = moment(time).format("mm");
  const disabledMinutesList = [];
  for (let index = 0; index < 60; index += 1) {
    if (currentMinute > index) {
      disabledMinutesList.push(index);
    }
  }
  return disabledMinutesList;
}

export function getDiffInHour(startDate, endDate) {
  const startDateMoment = moment(startDate);
  const endDateMoment = moment(endDate);
  const diffInHours = endDateMoment.diff(startDateMoment, "hours");
  return !(diffInHours < 24 && diffInHours >= 0);
}

// get the 2nd level child of post category i.e buy of classified
export function getPostChildSubCategory(category) {
  if (category?.parent_category?.parent_category) {
    return category;
  } else if (category?.parent_category) {
    return null;
  }
  return null;
}

// get the 1st level  post category i.e message, classified
export function getPostCategory(postCategory) {
  if (postCategory) {
    // * the post is normal post among the five types
    if (postCategory?.parent_category) {
      return getPostCategory(postCategory?.parent_category);
    } else {
      return postCategory;
    }
  } else {
    // * check if the post is sticky message, beacuse sticky message donot have post category
    return { slug: POST_TYPES.post_sticky };
  }
}

export function slugify(text) {
  const slug = text.toLowerCase().replace(/[^a-z0-9]+/g, "-");
  // Remove leading and trailing hyphens
  return slug.replace(/^(?:-+|-+)$/g, "");
}
export function removeSlugify(text) {
  let str = text.replace(/-/g, " ");
  // Capitalize first letter of each word
  str = str.replace(/\b\w/g, (c) => c.charAt(0).toUpperCase() + c.slice(1));
  return str;
}
export const daysList = [
  { value: "monday-friday", label: "Monday-Friday" },
  { value: "monday", label: "Monday" },
  { value: "tuesday", label: "Tuesday" },
  { value: "wednesday", label: "Wednesday" },
  { value: "thursday", label: "Thursday" },
  { value: "friday", label: "Friday" },
  { value: "saturday", label: "Saturday" },
  { value: "sunday", label: "Sunday" },
];

export const differenceBetweenStartAndEndDate = (createdAt, updatedAt) => {
  return moment
    .duration(moment(updatedAt).diff(moment(createdAt)))
    .asMilliseconds();
};

export const formattedFromNow = (momentDate) => {
  moment.updateLocale("en", {
    relativeTime: {
      future: "in %s",
      past: "%s ago",
      s: "just now",
      ss: "just now",
      m: "1 minute ago",
      mm: "%d minutes ago",
      h: "1 hour ago",
      hh: "%d hours ago",
      d: "1 day ago",
      dd: "%d days ago",
      M: "1 month ago",
      MM: "%d months ago",
      y: "1 year ago",
      yy: "%d years ago",
    },
  });
  return moment(momentDate).fromNow(true);
};

export const phoneNumRules = (required = false) => [
  {
    pattern: numberRegex,
    message: "Incorrect Phone Number",
  },
  { required: true, message: "Phone number is required" },
  { min: 8, message: "Phone number must be at least 8 digits" },
  { max: 10, message: "Phone number must not be greater than 10 digits" },
];

export const createSlugFromText = (text) => {
  if (text && text.trim() !== "") {
    return text.trim().replace(/ /g, "-").toLowerCase();
  } else {
    return "";
  }
};

export const isUserOrganizationManager = (dataSource, currentUser) => {
  let isManager = dataSource?.owned_by === currentUser?.user_id;

  if (dataSource?.organisation_staff?.length) {
    const { organisation_staff: organisationStaff } = dataSource;
    const isCurrentUserStaff = organisationStaff.find(
      (staff) => staff.user_id === currentUser?.user_id
    );
    if (isCurrentUserStaff) {
      const { is_can_manage: canManage } = isCurrentUserStaff;
      isManager = !!canManage;
    }
  }

  return isManager;
};

export const checkForAndReplaceUrl = (messageContent) => {
  const captureUrl =
    /(?<ContentBlock>(?<Url>(https?:\/\/)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+~#?&/={}$;,]*))(\s\|(?<Content>[^|]*)\|))/g;
  let msgContent = messageContent;
  const urls = Array.from(msgContent.matchAll(captureUrl));
  if (!urls) {
    return msgContent;
  }

  urls.forEach((url) => {
    const contentBlock = url.groups.ContentBlock;
    const content = url.groups.Content;

    msgContent = msgContent.replace(contentBlock, content);
  });

  // If you need to capture url in future ==> const url = urlObj.groups.Url;

  return msgContent;
};

// format error object containg error messages in array of string
export const formatErrors = (err) => {
  return Object.keys(err?.errors).map((value) => {
    return err?.errors[value][0];
  });
};

export const isPostToBeAppended = (postCategory, categorySlug) => {
  const noticeboardItems = ["noticeboard", "poll", "event"];
  if (categorySlug === "noticeboard") {
    return noticeboardItems.includes(postCategory?.slug);
  } else if (postCategory?.slug === categorySlug?.categorySlug) {
    return true;
  } else {
    // append post for every other category---> handling for undefined and all activity
    return true;
  }
};
