import React from "react";
import { Row, Col } from "antd";
import PropTypes from "prop-types";

function Container(props) {
  const { children, style, fluid, className } = props;
  const breakpoints = fluid
    ? {
        xs: 24,
        sm: 24,
        md: 24,
        lg: 24,
        xl: 24,
        xxl: 24,
      }
    : {
        xs: 24,
        sm: 24,
        md: 24,
        lg: 22,
        xl: 22,
        xxl: 16,
      };
  const defaultStyle = fluid
    ? {
        position: "relative",
        padding: "0rem 0rem",
        maxWidth: "1920px",
        overflow: "hidden",
        margin: "0 auto",
      }
    : {
        margin: "0 auto",
        padding: "1rem 1rem",
        position: "relative",
        maxWidth: "1920px",
        overflow: "hidden",
      };
  return (
    <Row className={`nb-container ${className}`}>
      <Col
        xs={breakpoints.xs}
        sm={breakpoints.sm}
        md={breakpoints.md}
        lg={breakpoints.lg}
        xl={breakpoints.xl}
        xxl={breakpoints.xxl}
        style={{ ...defaultStyle, ...style }}
      >
        {children}
      </Col>
    </Row>
  );
}
export default Container;

Container.defaultProps = {
  fluid: false,
  className: "",
};

Container.propTypes = {
  fluid: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]).isRequired,
  className: PropTypes.string,
};
