import React from "react";
import { Input, Spin } from "antd";
import { SearchOutlined, LoadingOutlined } from "@ant-design/icons";
import PropTypes from "prop-types";

// scss
import "./search.scss";

const Search = React.forwardRef(
  (
    {
      className,
      style,
      onFinish,
      onChange,
      suffix,
      placeholder,
      onFocus,
      allowClear,
      onPressEnter,
      searchLoader,
    },
    ref
  ) => {
    const antIcon = <LoadingOutlined style={{ fontSize: 18 }} spin />;
    const suffixJsx = suffix || (
      <button style={style} type="button" className="c-pointer nb-proxy-button">
        {searchLoader ? (
          <Spin className="mr2" indicator={antIcon} />
        ) : (
          <SearchOutlined className="mr2" style={{ fontSize: "18px" }} />
        )}
      </button>
    );
    return (
      <div className={`nb-search-input ${className}`}>
        <Input
          ref={ref}
          placeholder={placeholder}
          suffix={suffixJsx}
          onChange={(e) => {
            onChange?.(e?.target?.value);
          }}
          allowClear={allowClear}
          onFocus={(event) => onFocus?.(event)}
          onPressEnter={onPressEnter}
        />
      </div>
    );
  }
);

export default Search;

Search.defaultProps = {
  className: "",
  style: {},
  onFinish: undefined,
  onChange: undefined,
  suffix: undefined,
  onFocus: undefined,
  placeholder: "Search",
  allowClear: false,
};

Search.propTypes = {
  className: PropTypes.string,
  style: PropTypes.objectOf(PropTypes.any),
  onFinish: PropTypes.func,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  suffix: PropTypes.func,
  onFocus: PropTypes.func,
  allowClear: PropTypes.bool,
};
