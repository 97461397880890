import React from "react";
import { Modal } from "antd";

// components
import MessageJourney from "../../../components/createPost/journey/MessageJourney";

export default function EditPostModal({
  title,
  visible,
  onCancel,
  postData,
  editMode,
  maskClosable,
  closable,
  onPostCreated,
  children,
}) {
  if (!visible) return null;

  /* istanbul ignore next */
  const renderPostEditComponent = () => {
    return (
      <MessageJourney
        editMode={editMode}
        dataSource={postData?.data}
        closeEditModal={onCancel}
        onPostCreated={onPostCreated}
      />
    );
  };

  return (
    <Modal
      title={
        title && (
          <span className="tt-capitalize">
            Edit
            {` ${title} `}
            Post
          </span>
        )
      }
      open={visible}
      footer={false}
      centered
      className="update-post-modal"
      destroyOnClose
      onCancel={onCancel}
      maskClosable={maskClosable}
      closable={closable}
    >
      <div className="update-post-modal-content">
        {children || renderPostEditComponent()}
      </div>
    </Modal>
  );
}
/* istanbul ignore next */
EditPostModal.defaultProps = {
  editMode: true,
  maskClosable: false,
  title: undefined,
  closable: true,
  onPostCreated: () => null,
};
