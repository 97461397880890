import Echo from "laravel-echo";
import service from "../api/axoisBase";
import config from "../config";

let echo = null;
const persistRoot = JSON.parse(localStorage.getItem("persist:root"));
const token = JSON.parse(persistRoot?.auth || "{}")?.Token;
const pusherOptions = { ...config.pusherOptions(token) };

export const getEchoInstance = () => {
  try {
    if (echo === null) {
      echo = new Echo(pusherOptions);
      echo.connector.pusher.connection.bind("connected", () => {
        service?.interceptors?.request.use((req) => {
          req.headers = {
            ...req.headers,
            "X-Socket-Id": echo.socketId(),
          };
          return req;
        });
      });
    }
    return echo;
  } catch (err) {
    console.error(err);
    return null;
  }
};
